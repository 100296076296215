import { UiService } from './../../services/ui.service';
import { Router } from '@angular/router';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DataService } from '../../services/data.service';

declare var $;

@Component({
  selector: 'home',
  templateUrl: 'home.component.html',
  styleUrls: ['home.component.css']
})
// , '../../digi-lib/components/digi-library-assets/css/style.css'
export class HomeComponent implements OnInit {

  @ViewChild('searchBox', { static: false }) searchBox: ElementRef
  @ViewChild('searchBoxMob', { static: false }) searchBoxMob: ElementRef
 

  superNavigationLevel = [] //Level 1 navigation
  subNavLinks = [] //Level 2 navigation
  subSubNavLinks = [] //Level 3 navigation

  subNavTarget = "" //Level 1 target
  subSubNavTarget = "" //Level 2 target

  isSuperNavClicked = false
  isSubNavClicked = false
  navigationLinks: unknown;
  ebooksUrl: any;
  audioboksUrl: any;
  videosUrl: any;
  errorMessage;

  constructor(private dataService: DataService,private router:Router, private uiService:UiService) {
   
  }

  ngOnInit(): void {
    var viewportWidth = $(window).width();

    function comment(element)
  {
      element.html('<!--' + element.html() + '-->')
  }

  function uncomment(element){
    var len = element.html().length;
    element.html(element.html().substring(4, len - 3))
}
  
 
  if (viewportWidth <= 991) {
    $('.container-fluid').click(function(){
      $("div.spanner").removeClass("show");
      $('.mob-search').hide();
      $('.navbar-toggler,.fab-container,.backLink').show();
     
     });
     
  }else{
    $('.nav-item, .content-wrapper').click(function(){
      $("div.spanner").removeClass("show");
     });
    
  }

  
    $('.fab-container').show();
    $('.breadcrumb-mob, .breadcrumb-com').hide();
    $('.nav-item-parent').click(function(){
      $(this).addClass('active').siblings().removeClass('active');
      $('.navbar-collapse').collapse('hide');
   });

   $('.nav-item-child').click(function(){
    $('li').removeClass('active');
    $(this).addClass('active1').siblings().removeClass('active1');
    $('.navbar-collapse').collapse('hide');
 });
 
 $('.overlay').click(function(){
  $('.mob-logo').hide(); 
  $("div.spanner").addClass("show");
  $('.mob-search').show();
  $('.navbar-toggler,.fab-container,.backLink').hide();
 });

 $('.com-search').click(function(){
  $("div.spanner").addClass("show");
 });

 
 

      $('#dialogg').modal('show');
      $('.backLink').click(function(){
        parent.history.go(-1);
        return false;
        
    });
  
    $('.forwardLink').click(function(){
      parent.history.go(+1);
      return false;
    });
    
    $('.recent-item-click').click(function(){
    $('.bottom-nav-recent').show();
    $('.bottom-nav-discover').hide();
    });

    $('.popular-ebooks-click').click(function(){
      $('.sections').hide();
      $('.popular-ebooks').show();
      $('.nav__link').removeClass('nav__link--active');
      $('.popular-ebooks-click').addClass('nav__link--active');
    });
   
    $('.popular-audios-click').click(function(){
      $('.sections').hide();
      $('.popular-audios').show();
      $('.nav__link').removeClass('nav__link--active');
      $('.popular-audios-click').addClass('nav__link--active');
    });

    $('.popular-videos-click').click(function(){
      $('.sections').hide();
      $('.popular-videos').show();
      $('.nav__link').removeClass('nav__link--active');
      $('.popular-videos-click').addClass('nav__link--active');
    });
   
    $('.recent-click').click(function(){
      $('.sections').hide();
      $('.recent').show();
      $('.nav__link').removeClass('nav__link--active');
      $('.recent-click').addClass('nav__link--active');
    });

   
    



    this.dataService.getInitialFeed().then(entries => {
      (entries as any).forEach(entry => {
        if (!(entry.title._text as String).includes("Tertiary")) {
          this.superNavigationLevel.push(entry)
        }
      });
    })
    
}



navClicked(level) {
    
  this.isSuperNavClicked = false
  this.subNavLinks = []

  this.dataService.getIsPenUlView(level.link._attributes.href).then(isPenUltimateField => {
    this.isSuperNavClicked = true
    if (!isPenUltimateField) {
      this.dataService.pullFromFeed(level.link._attributes.href).then(entries => {
        this.subNavLinks = entries as any
        this.subNavTarget = level.title._text
      })
    }
  })
}

subNavClicked(subLevel){
  this.isSubNavClicked = false
  this.subSubNavLinks = []

  this.dataService.getIsPenUlView(subLevel.link._attributes.href).then(isPenUltimateField => {
    this.isSubNavClicked = true
    if (!isPenUltimateField) {
      this.dataService.pullFromFeed(subLevel.link._attributes.href).then(entries => {
      
        this.subSubNavLinks = entries as any
        this.subSubNavTarget = subLevel.title._text
      })
    }else{
     this.routeToCoursesView(subLevel.link._attributes.href)
    }
  })
}



//Assumption is that it will be a penultimate level as in the entries in this levels will lead straight to content
subSubNavClicked(subSubLevel){
  this.routeToCoursesView(subSubLevel.link._attributes.href)
}



routeToCoursesView(link){
    this.router.navigate(["/home/Courseselection"],{queryParams:{url:encodeURI(link)}})
}


logout(){
  localStorage.clear()
  window.location.reload()
}


showContent(hrefToForms,targetName) {

  // let loader = new Loader();
  // loader.show()

  // setTimeout(()=>{
  //   loader.hide()
  // },10000);


  this.dataService.pullFromFeed(hrefToForms).then(entries => {

    this.dataService.getFeedNavLinks().then(links=>{
      this.navigationLinks = links
      // console.log(this.navigationLinks)
    

      this.ebooksUrl = entries[0].link._attributes.href;
      this.audioboksUrl = entries[1].link._attributes.href;
      this.videosUrl = entries[2].link._attributes.href;


      // console.log(this.ebooksUrl)

    


      this.router.navigate(['/home/content'], {
        queryParams: {
          initial:hrefToForms,
          title: targetName,
          ebooks: this.ebooksUrl,
          audiobooks: this.audioboksUrl,
          videos: this.videosUrl,
          navLinks:JSON.stringify(this.navigationLinks)
        }
      })

    })

  
  }).catch(err=>{
    // loader.hide()
  })

}


search() {
  // alert(this.searchBox.nativeElement.value)
  // this.router.navigate(['/home/search'])

  // this.uiService.showLoader()

  let queryTerms = this.searchBox.nativeElement.value

  if (queryTerms.length > 2) {
    // let p = new Loader()
    // p.show()
    $('.loader').css('display','block');
    this.dataService.search(queryTerms).then(entries => {
      // p.hide()
      // this.uiService.hideLoader()
      ///console.log(entries)
       this.router.navigate(['/home/search'], { queryParams: { params: JSON.stringify(entries)  } })
       $("div.spanner").removeClass("show");
       $('.loader').css('display','none');
      //  window.location.reload()
    }, error => {
      // p.hide()
      // this.uiService.hideLoader()
      this.errorMessage = "No Entries"
      alert(this.errorMessage)

      this.uiService.showDialog()
      // new Alerter().showError("No entries")
    })
  } else {
    // this.uiService.hideLoader()
  
    this.errorMessage = "Keywords must be more than 2 letters long!"
    alert(this.errorMessage)
    this.uiService.showDialog()
    // new Alerter().showError("Keywords must be more than 2 letters long!")
  }

}


searchMob() {
  // alert(this.searchBox.nativeElement.value)
  // this.router.navigate(['/home/search'])

  // this.uiService.showLoader()

  let queryTerms = this.searchBoxMob.nativeElement.value

  if (queryTerms.length > 2) {
    // let p = new Loader()
    // p.show()
    $('.loader').css('display','block');
    this.dataService.search(queryTerms).then(entries => {
      // p.hide()
      // this.uiService.hideLoader()
      ///console.log(entries)
       this.router.navigate(['/home/search'], { queryParams: { params: JSON.stringify(entries) }})
       $("div.spanner").removeClass("show");
       $('.loader').css('display','none');
      //  window.location.reload()
    }, error => {
      // p.hide()
      // this.uiService.hideLoader()
      this.errorMessage = "No Entries"
      alert(this.errorMessage)

      this.uiService.showDialog()
      // new Alerter().showError("No entries")
    })
  } else {
    // this.uiService.hideLoader()
  
    this.errorMessage = "Keywords must be more than 2 letters long!"
    alert(this.errorMessage)
    this.uiService.showDialog()
    // new Alerter().showError("Keywords must be more than 2 letters long!")
  }

}


}





