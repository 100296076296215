

<main id="main">

    

    <!-- ======= Pricing Section ======= -->
    <section id="pricing" class="pricing news">
      <div class="container" data-aos="fade-up" style='height:250px;'>

       

      </div>
    </section><!-- End Pricing Section -->
 <!-- ======= Breadcrumbs ======= -->
 <section id="breadcrumbs" class="breadcrumbs" style='background:white;'>
   <div class="container" data-aos="fade-up">

     <ol>
       <li><a routerLink='/'>Home</a></li>
       <li>About</li>
     </ol>
     <h2 class='text'>Services</h2>

   </div>
 </section><!-- End Breadcrumbs -->



 <section id="faq" class="faq section-bg" >
    <div class="row flex-column-reverse flex-md-row">
        <div class="col-md-8">
            <div class="container menuItem services" data-aos="fade-up">

                <div class="section-title">
                  <h2>Our Services</h2>
                  <p>The Ghana Library Authority</p>
                </div>
        
                <div class="faq-list">
                  <ul>
                    <li data-aos="fade-up" data-aos-delay="100">
                      <i class="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" class="collapse" href="#faq-list-1">Technology Training <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                      <div id="faq-list-1" class="collapse show" data-parent=".faq-list">
                        <p >
                          Across 30 branches, the GhLA offers the opportunity for both school children and adults to learn various aspects of the use of technological devices to solve everyday problems. This program forms the heart of our work to equip Ghanaians with modern skills in retrieving information and exploiting tools of technology to help advance their wellbeing.
                        </p>
                      </div>
                    </li>
        
                    <li data-aos="fade-up" data-aos-delay="200">
                      <i class="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" href="#faq-list-2" class="collapsed">Corporate Institutional Book Lending <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                      <div id="faq-list-2" class="collapse" data-parent=".faq-list">
                        <p>GhLA understands the need of corporate Ghana to be in tuned with current
                            publications. Our Corporate Book Lending is targeted at all levels of business entities,
                            wishing to build a reading and knowledgeable workforce. By signing up for Corporate
                            Book Lending, your institution can visit our regional branches and make selection of
                            books that could be helpful to your team.</p>
                      </div>
                    </li>
        
                    <li data-aos="fade-up" data-aos-delay="300">
                      <i class="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" href="#faq-list-3" class="collapsed">Individual Book Lending <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                      <div id="faq-list-3" class="collapse" data-parent=".faq-list">
                        
                                   
                    <p>Individuals, both children and adults can apply for membership and have access to our branches around the country and borrow books of their choice.</p>
                      </div>
                    </li>

                    <li data-aos="fade-up" data-aos-delay="500">
                        <i class="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" href="#faq-list-5" class="collapsed">Family Book Lending <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                        <div id="faq-list-5" class="collapse" data-parent=".faq-list">
                        
                            <p>You can sign up as a Family and borrow books up to 25 copies and return in 21 days. This service is offered in all sixteen regions of Ghana.</p>
                        </div>
                      </li>
  
                     
          
        
                    <li data-aos="fade-up" data-aos-delay="400">
                      <i class="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" href="#faq-list-4" class="collapsed">School Book Lending <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                      <div id="faq-list-4" class="collapse" data-parent=".faq-list">
                       
                        <p>Does your school lack library services? Or has limited book stock? Join our School Book Lending program and borrow a box of books to be used by your school.</p>
                      </div>
                    </li>
        
                   
                      
                      <li data-aos="fade-up" data-aos-delay="500">
                        <i class="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" href="#faq-list-6" class="collapsed">Mobile Library (Library on Wheels) <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                        <div id="faq-list-6" class="collapse" data-parent=".faq-list">
                        
                      <p>We offer mobile library services to unserved schools and communities across the
                        country. The GhLA currently has ten Mobile Library Vans across the country.</p>
                        </div>
                      </li>

                      <li data-aos="fade-up" data-aos-delay="500">
                        <i class="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" href="#faq-list-7" class="collapsed">Reference Support Services <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                        <div id="faq-list-7" class="collapse" data-parent=".faq-list">
                        
                           
                            <p>Are you a researcher and require specific materials? Our reference
                               section staff are available to help you. Contact any of our Regional Libraries.</p>
                            </div>
                      </li>

                      <li data-aos="fade-up" data-aos-delay="500">
                        <i class="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" href="#faq-list-8" class="collapsed">Printing, Scanning and Photocopying <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                        <div id="faq-list-8" class="collapse" data-parent=".faq-list">
                    
                            <p>Printing, scanning and photocopying services are provided at many of our libraries in major cities. Contact your nearest library for more information.</p>
                        </div>
                      </li>


                      <li data-aos="fade-up" data-aos-delay="500">
                        <i class="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" href="#faq-list-9" class="collapsed">Issuance of ISBN, ISMN, ISNN, CIP <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                        <div id="faq-list-9" class="collapse" data-parent=".faq-list">
                        
                        </div>
                      </li>

                      <li data-aos="fade-up" data-aos-delay="500">
                        <i class="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" href="#faq-list-10" class="collapsed">Legal Deposit <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                        <div id="faq-list-10" class="collapse" data-parent=".faq-list">
                        
                            <p>Under Book and Newspaper Registration Act 1961, Publishers/ Authors are required to deposit copies of materials published in Ghana with the Ghana Library Authority. The deposited materials are
                               used in building up the Ghana National Bibliography.</p>
                        </div>
                      </li>

                      <li data-aos="fade-up" data-aos-delay="500">
                        <i class="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" href="#faq-list-11" class="collapsed">Research <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                        <div id="faq-list-11" class="collapse" data-parent=".faq-list">
                        
                      <p>We undertake research on behalf of Government, businesses and varied agencies. To
                        sign up for any of our research service, do not hesitate to reach out to us.</p>
                        </div>
                      </li>

                      <li data-aos="fade-up" data-aos-delay="500">
                        <i class="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" href="#faq-list-12" class="collapsed">Library Consultancy (Schools, MDAs, Corporations) <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                        <div id="faq-list-12" class="collapse" data-parent=".faq-list">
                        
                      <p>Do you desire to construct or set up a library within your institution? Sign up for our library consultancy service and receive professional input on the development, design, management and stocking of your library.</p>
                        </div>
                      </li>

                      <li data-aos="fade-up" data-aos-delay="500">
                        <i class="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" href="#faq-list-13" class="collapsed">Co-Working Space <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                        <div id="faq-list-13" class="collapse" data-parent=".faq-list">
                        
                            <p>Do you need a space to work? Our co-working space provides an opportunity for entrepreneurs to sit in the comfort of like-minded people to develop their businesses. Our space is situated in a conducive environment which enables your business to find its balance within the ecosystem before moving out. Interested persons should email us on
                                <a href="#" style='color:blue;'> estates@library.gov.gh</a></p>
                        </div>
                      </li>
          
        
                  </ul>
                </div>
        
              </div>

              <div class="container menuItem about" data-aos="fade-up" style='display:none;'>

                <div class="section-title">
                  <h2>About Us</h2>
                  <p>The Ghana Library Authority</p>
                </div>
        
                <div class="faq-list">
                  <ul>
                    <li data-aos="fade-up" data-aos-delay="100">
                      <i class="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" class="collapse" href="#faq-list-1">About Us <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                      <div id="faq-list-1" class="collapse show" data-parent=".faq-list">
                        <p >
                            The Ghana Library Authority (GhLA) was established in 1949 
                            by the Gold Coast Ordinance CAP 118 
                            and came into effect in January, 1950. The Ordinance was 
                            re-enacted as the Ghana Library Board Act, 1970 (Act 327). Act 327 
                            was also amended by Act 562 of 1998 for Ghana Library Board to become Ghana Library Authority.
                        </p>
                      </div>
                    </li>
        
                   
        
                  </ul>
                </div>
        
              </div>
           

              <div class="container menuItem history" data-aos="fade-up" style='display:none;'>

                <div class="section-title">
                  <h2>History & Vision</h2>
                  <p>The Ghana Library Authority</p>
                </div>
        
                <div class="faq-list">
                  <ul>
                    <li data-aos="fade-up" data-aos-delay="100">
                      <i class="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" class="collapse" href="#faq-list-1">History & Vision <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                      <div id="faq-list-1" class="collapse show" data-parent=".faq-list">
                        <p >
                            The Ghana Library Authority (GhLA) was formally established in 1950 and forms a critical part of Ghana's historical artefacts. It was setup to improve literacy outcomes, which is key to the development of the country. This is done through the provision of information services which seek to improve reading outcomes, leading to increased public awareness as a critical component of the overall strategy towards tackling illiteracy, poverty, disease and unemployment related problems.
                        </p>

                        <p>
                            Over the years however, the GhLA has experienced monumental challenges in maintaining operations with a view to enabling literacy driven efficacy. These challenges are varied and multifaceted in nature and range from financial, operational, service delivery and program efficacy barriers faced by the organization. These internal and external drivers have exerted negative push and pull forces on the organization driving it into a de-prioritised organization within the universe of public services.
                        </p>

                        <p>The GhLA is entering a new phase of rebirth after years of stagnation with a view to achieving growth, diversifying delivery channels; radical redesign of library services, business model & service delivery optimisation, organisational restructure and leadership transformation.</p>

                        <p>According to the Ghana Statistical Service (2010 Census), the national average for literacy in Ghana is 74.1% with some regions in the lower quartile recording as low as 37.2%. Such statistics galvanize well meaning global citizens to act with a view to changing the status quo.</p>

                        <p>Internal insights suggest strong patronage across our network by Ghanaians of all age groups as they seek to utilize our core services or facilities for self-study. This inherently is a demonstration of the uniquely untapped potential available to grow library services and its associated efficacy related impact.</p>

                        <p>Inherent in this universe of problems is an unprecedented opportunity for your organisation to actively contribute towards improving the efficacy of library services in direct support of this country's development growth agenda. Three innovative product models have been designed to allow for easy integration with our core program delivery model. This is expected to enable a strong value proposition for your business inclusive of the following â€“ (1) potential tax reliefs, (2) the ability to articulate a dual purpose narrative which revolves around business growth specifically profitability while driving social impact; (3) increase government and community relations through engagement with this innovative initiative while (4) increasing volunteerism within your organisation.</p>

                        <p>This working relationship will be enabled through the execution of an MOU to formalise working relationships and provides the framework for world class performance measurement and reporting on your social impact investment portfolio. It is envisaged such reporting will cover social impact, operational, customer experience and program efficacy related metrics which will be shared with your organisation on a quarterly basis. Such reporting will be used to drive partner engagement discussions as we seek to collectively improve reading habits in Ghana.</p>
                      </div>
                    </li>
        
                    <li data-aos="fade-up" data-aos-delay="200">
                      <i class="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" href="#faq-list-2" class="collapsed">Origin and Growth <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                      <div id="faq-list-2" class="collapse" data-parent=".faq-list">
                        <p>The Ghana Library Authority (formerly Ghana Library Board) was established out of a generous contribution of Â£1,000 donated by the late RT. Rev. John Orfeur Anglionby, the then Anglican Bishop of Accra. In 1946, the Aglionby Library Management Committee worked with the British Council Advisory Committee towards library development in the then Gold Coast. The work of the Committee resulted in the passing of the Gold Coast Library Board Ordinance, Cap 118, in December, 1949, which became operational on January 1, 1950.</p>
                        <p>The Library Service run by the Ghana Library Authority (GhLA) then became the defacto public library service in Ghana. It is one of the pre-independence heritages birthed in a single room in 1950 at the King George Memorial Hall with an initial collection of 27,000 books and total member of 3,000. Today the GhLA which epitomizes one of the rich heritages of this country has grown to possess a total of almost 700,000 books underpinned by a membership supported by a footprint of 63 branches (10 Regional Libraries and 53 Branches) across the country. Over the years the Ghana Library Authority's service has experienced monumental challenges in maintaining operations with a view to enabling literacy driven efficacy. The noble objective of positioning the GhLA as a best in class institution which seeks to reinforce good reading habits can be seen to be at risk given the monumental financial, operational and program efficacy challenges faced by the organization. According to the UNESCO Institute for Statistics, global literacy rate for all people aged 15 and above (15+ polled together as adult growth rates) is positioned at 86.3% with Ghana currently positioned at 76.6% in 2015 from 57.9% at an average annual growth rate of 15.30%. This however discounts literacy rates of non-adults (those below 15+) where there is a dire need to improve literacy if improvements in adult literacy rates are to be sustained.</p>
                        <p>There is therefore an urgent need and a necessarily requisite call to action, with the need for an associate positive response driven by champions of good such as your organisation in partnership with the GhLA to collectively influence literacy rates in Ghana through the power of the collective in influencing reading habits.</p>
                      </div>
                    </li>
        
                    <li data-aos="fade-up" data-aos-delay="300">
                      <i class="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" href="#faq-list-3" class="collapsed">Public Library system, an enabler of development <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                      <div id="faq-list-3" class="collapse" data-parent=".faq-list">
                        
                                   
                    <p>The work that the GhLA does goes a long way to determine the extent to which information can first be stored, accessed and managed in a way that enhances the ability of the public to acquire knowledge in satisfaction of their general information and specific educational needs. Such combined repository of knowledge and its associated usage is consequentially critical to our ability to tackle illiteracy, poverty, disease, unemployment related problems.</p>
                    <p>As part of carrying out its mandate, the GhLA engages in activities like preparing and disseminating guidelines for the establishment of public, community and school libraries to improve the capacity of the population in reading and information seeking and retrieval towards life-long learning.</p>
                    <p>The GhLA through its regional and branch libraries is focused on encouraging the public to enjoy and excel in reading, writing, general literacy and general information share across all age groups.</p>
                    <p>Such shared learning when adequately scaled leads to the evolution of skilled, well rounded human resources critical to the development of the country.</p>
                      </div>
                    </li>

                    
          
        
                  </ul>
                </div>
        
              </div>

              <div class="container menuItem donation" data-aos="fade-up" style='display:none;'>

                <div class="section-title">
                  <h2>Make a Donation</h2>
                  <p>The Ghana Library Authority</p>
                </div>
        
                <div class="faq-list">
                  <ul>
                    <li data-aos="fade-up" data-aos-delay="100">
                      <i class="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" class="collapse" href="#faq-list-1">Make a Donation <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                      <div id="faq-list-1" class="collapse show" data-parent=".faq-list">
                        <p >
                            Send us an email at <b>partnerships@ghanalibrary.org</b>. We will reach back to you with the details to make it possible. Thank you for considering to support our library services.
                        </p>
                      </div>
                    </li>
        
                   
        
                  </ul>
                </div>
        
              </div>

              <div class="container menuItem partnership" data-aos="fade-up" style='display:none;'>

                <div class="section-title">
                  <h2>Corporate Partnership</h2>
                  <p>The Ghana Library Authority</p>
                </div>
        
                <div class="faq-list">
                  <ul>
                    <li data-aos="fade-up" data-aos-delay="100">
                      <i class="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" class="collapse" href="#faq-list-1">Corporate Partnership <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                      <div id="faq-list-1" class="collapse show" data-parent=".faq-list">
                        <p >
                            An investment in library, is an investment in the soul and respect of the country- its people, its knowledge. We invite citizen centred corporations to partner with us in promoting a reading lifestyle in Ghana. Corporations can adopt a library within their operational region or partner with us in diverse innovative program areas. Ghana Library Authority is a public sector institution with trusted identity. Partners benefit from improve brand identity through various opportunities.
                        </p>

                        <p>Your business also benefits from a range of access to our business book collection, meeting and conference rooms on agreed highly discounted rate around the country.</p>
                        <p>Be part of the transformation of library service in Ghana. Send us an email <b>partnerships@ghanalibrary.org</b></p>
                      </div>
                    </li>
        
                   
        
                  </ul>
                </div>
        
              </div>

              <div class="container menuItem foundations" data-aos="fade-up" style='display:none;'>

                <div class="section-title">
                  <h2>Foundations, Trust and Philanthropic Support</h2>
                  <p>The Ghana Library Authority</p>
                </div>
        
                <div class="faq-list ">
                  <ul>
                    <li data-aos="fade-up" data-aos-delay="100">
                      <i class="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" class="collapse" href="#faq-list-1">Foundations, Trust and Philanthropic Support <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                      <div id="faq-list-1" class="collapse show" data-parent=".faq-list">
                        <p >
                            Ghana Library Authority since its inception has not made a deliberate attempt to tap into philanthropic support. With the new growth agenda, leadership believes, the development of our library system can best be achieved with joint effort of like-minded individuals, trusts, and foundations. GhLA has wide ranging projects in need of philanthropic support to transform our communities. </p>

                        <p>Ways to help: </p>
                        <ul >
                            <li style='padding-bottom: 0px;'><i class="ri-check-double-line"></i> Early Childhood Reading program support to cognitive development</li>
                            <li style='padding-top: 0px; padding-bottom: 0px;'><i class="ri-check-double-line"></i> Mobile Library services for rural schools and communities</li>
                            <li style='padding-top: 0px; '><i class="ri-check-double-line"></i> Establishment of Disability Learning Support Units at all Regional Libraries.</li>
                            <li style='padding-top: 0px; '><i class="ri-check-double-line"></i>Library Development Fund (an endowment to support training and capacity needs of library practitioners, as well as infrastructure).</li>
                          </ul>
                      </div>
                    </li>
        
                   
        
                  </ul>
                </div>
        
              </div>

              <div class="container menuItem adopt" data-aos="fade-up" style='display:none;'>

                <div class="section-title">
                  <h2>Adopt a Library</h2>
                  <p>The Ghana Library Authority</p>
                </div>
        
                <div class="faq-list">
                  <ul>
                    <li data-aos="fade-up" data-aos-delay="100">
                      <i class="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" class="collapse" href="#faq-list-1">Adopt a Library<i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                      <div id="faq-list-1" class="collapse show" data-parent=".faq-list">
                        <p >
                            This proposal is a call out to your institution to adopt a Library node on our network with a view to improving literacy and learning outcomes in Ghana. This presents a unique opportunity to improve the lives of people of all age groups especially children with a view to building the next generation of world class human resources relative to the sub-region. Three Library adoption models are available for your organisation to implement.
                        </p>
                      </div>
                    </li>
        
                    <li data-aos="fade-up" data-aos-delay="200">
                      <i class="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" href="#faq-list-2" class="collapsed">Model I Adopt a Regional Library <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                      <div id="faq-list-2" class="collapse" data-parent=".faq-list">
                        <p>This option enables your company to adopt a regional Library. A typical regional library has an average visit of of 6000 people in a month and is positioned as a centre for reading by all age groups and self-learning for professional students.</p>
                        <p>An adoptee organization's investment will be used for the following:</p>
                        <ul >
                            <li style='padding-bottom: 0px;'><i class="ri-check-double-line"></i> Paying of utilities</li>
                            <li style='padding-top: 0px; padding-bottom: 0px;'><i class="ri-check-double-line"></i> General operations/Programs/Events</li>
                            <li style='padding-top: 0px;  padding-bottom: 0px;'><i class="ri-check-double-line"></i> Repairs and maintenance</li>
                            <li style='padding-top: 0px;  padding-bottom: 0px;'><i class="ri-check-double-line"></i> Procurement of books, equipment, furniture</li>
                            <li style='padding-top: 0px; padding-bottom: 0px;'><i class="ri-check-double-line"></i> Subscription of Journals, Digital Content</li>
                            <li style='padding-top: 0px;  padding-bottom: 0px;'><i class="ri-check-double-line"></i> Provision of Internet to users</li>
                            <li style='padding-top: 0px;  padding-bottom: 0px;'><i class="ri-check-double-line"></i> Capacity Building</li>
                          </ul>

                          <p>Such an involvement in literacy in Ghana will be enabled at an annual contribution of <b>70,000 GHC.</b></p>
                          <p>Send us an email at <b>partnerships@ghanalibrary.org</b>. We will get back to you with the details to make it possible.</p>
                      </div>
                    </li>
        
                    <li data-aos="fade-up" data-aos-delay="300">
                      <i class="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" href="#faq-list-3" class="collapsed">Model II Adopt a Mobile Van <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                      <div id="faq-list-3" class="collapse" data-parent=".faq-list">
                        
                                   
                    <p>Model III enables the adoption of a mobile library van. Our Mobile Library vans have an average eyeball of about 5000 people a month as it travels through town and engages in program related activities. </p>
                    <p>An adoptee organization's investment will be use for the following:</p>
                                       <ul >
                                        <li style='padding-bottom: 0px;'><i class="ri-check-double-line"></i> General operations/Programs/Events</li>
                                        <li style='padding-top: 0px; padding-bottom: 0px;'><i class="ri-check-double-line"></i> Overnight allowance of driver and Librarian</li>
                                        <li style='padding-top: 0px;  padding-bottom: 0px;'><i class="ri-check-double-line"></i> Maintenance and Repair of Library Van</li>
                                        <li style='padding-top: 0px;  padding-bottom: 0px;'><i class="ri-check-double-line"></i> Weekly fuel to and from program sites</li>
                                        <li style='padding-top: 0px; padding-bottom: 0px;'><i class="ri-check-double-line"></i> Procurement of books, equipment and furniture</li>
                                        <li style='padding-top: 0px;  padding-bottom: 0px;'><i class="ri-check-double-line"></i> Subscription of Journals, Digital Content</li>
                                        <li style='padding-top: 0px;  padding-bottom: 0px;'><i class="ri-check-double-line"></i> Provision of Internet to users</li>
                                        <li style='padding-top: 0px;  padding-bottom: 0px;'><i class="ri-check-double-line"></i> Capacity Building</li>
                                      </ul>
                   <p>This model can be activated at an annual contribution of <b>50,000 GHC.</b></p>
                   <p>Send us an email at <b>partnerships@ghanalibrary.org</b>. We will get back to you with the details to make it possible.</p>
                      </div>
                    </li>

                    
        
                    <li data-aos="fade-up" data-aos-delay="400">
                      <i class="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" href="#faq-list-4" class="collapsed">Model III Adopt a Branch Library <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                      <div id="faq-list-4" class="collapse" data-parent=".faq-list">
                       
                        <p>This option enables the adoption of a branch library. A typical branch Library has an average visit of 3000 people in a month and is positioned as a centre for reading by all age groups and self-learning for professional students.</p>
                        <p>An adoptee organization's investment will be use for the following:</p>
                        <ul >
                            <li style='padding-bottom: 0px;'><i class="ri-check-double-line"></i> Paying of utilities</li>
                            <li style='padding-top: 0px; padding-bottom: 0px;'><i class="ri-check-double-line"></i> General operations/Programs/Events</li>
                            <li style='padding-top: 0px;  padding-bottom: 0px;'><i class="ri-check-double-line"></i> Repairs and maintenance</li>
                            <li style='padding-top: 0px;  padding-bottom: 0px;'><i class="ri-check-double-line"></i> Procurement of books, equipment, furniture</li>
                            <li style='padding-top: 0px; padding-bottom: 0px;'><i class="ri-check-double-line"></i> Subscription of Journals, Digital Content</li>
                            <li style='padding-top: 0px;  padding-bottom: 0px;'><i class="ri-check-double-line"></i> Provision of Internet to users</li>
                            <li style='padding-top: 0px;  padding-bottom: 0px;'><i class="ri-check-double-line"></i> Capacity Building</li>
                          </ul>

                          <p>Such an involvement in literacy in Ghana will be enabled at an annual contribution of <b>70,000 GHC.</b></p>
                          <p>Send us an email at <b>partnerships@ghanalibrary.org</b>. We will get back to you with the details to make it possible.</p>
                      </div>
                    </li>
        
            
          
        
                  </ul>
                </div>
        
              </div>

              <div class="container menuItem coalition" data-aos="fade-up" style='display:none;'>

                <div class="section-title">
                  <h2>Coalition of NGOs in Library Development</h2>
                  <p>The Ghana Library Authority</p>
                </div>
        
                <div class="faq-list">
                  <ul>
                    <li data-aos="fade-up" data-aos-delay="100">
                      <i class="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" class="collapse" href="#faq-list-1">Coalition of NGOs in Library Development <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                      <div id="faq-list-1" class="collapse show" data-parent=".faq-list">
                        <p >
                            The Coalition of NGOs in Library Development brings development actors from the grassroots to national level players to advocate for increase commitment from policy makers and government to support Library services provision for Ghanaians. Join the Coalition by writing to  <b>partnerships@ghanalibrary.org</b>
                        </p>
                      </div>
                    </li>
        
                   
        
                  </ul>
                </div>
        
              </div>

              <div class="container menuItem patronage" data-aos="fade-up" style='display:none;'>

                <div class="section-title">
                  <h2>Become a Patron of Ghana Library</h2>
                  <p>The Ghana Library Authority</p>
                </div>
        
                <div class="faq-list">
                  <ul>
                    <li data-aos="fade-up" data-aos-delay="100">
                      <i class="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" class="collapse" href="#faq-list-1">Become a Patron of Ghana Library <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                      <div id="faq-list-1" class="collapse show" data-parent=".faq-list">
                        <p >
                            Our Patrons share our collective interest in life-long learning. They help us to reach new heights to serve the interest of our visitors. Besides providing public library services, Ghana Library Authority holds written memoir of Ghana through the legal deposit. 
                        </p>
                        <p>As a patron, you will become part of people with profound interest and fidelity to library as a catalyst to transform Ghana's development. A learned nation, is a winning nation. Patrons will benefit from exclusive access to key events, exhibitions, screenings, meeting rooms, conferences, CEO & Chairperson's Interaction forum, as well Minister's library forum. </p>
                        <p>We have introduced a three tier support levels with a line-up of benefits</p>
                        <ul >
                            <li style='padding-bottom: 0px;'><i class="ri-check-double-line"></i> Shakespeare Patrons- 10,000 GHC</li>
                            <li style='padding-top: 0px; padding-bottom: 0px;'><i class="ri-check-double-line"></i> Hemingway Patron - 5000 GHC</li>
                            <li style='padding-top: 0px;  padding-bottom: 0px;'><i class="ri-check-double-line"></i> Mark Twain Patrons -  2000 GHC </li>
                          </ul>
                        <p>Your support to Ghana Library Authority will make a difference in enhancing our work to make Ghana a reading nation. Please contact Mrs Yaa Agyemang Opare Adu, Head of Partnerships and Programmes on  <b>partnerships@ghanalibrary.org</b></p>
                      </div>
                    </li>
        
                   
        
                  </ul>
                </div>
        
              </div>

              <div class="container menuItem membership" data-aos="fade-up" style='display:none;'>

                <div class="section-title">
                  <h2>Become a Member</h2>
                  <p>The Ghana Library Authority</p>
                </div>
        
                <div class="faq-list">
                  <ul>
                    <li data-aos="fade-up" data-aos-delay="100">
                      <i class="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" class="collapse" href="#faq-list-1">Become a Member <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                      <div id="faq-list-1" class="collapse show" data-parent=".faq-list">
                        <p >
                            We exist because of you! Visit our nearest library and register as a member. 
                        </p>
                        <p>Benefit of Membership. </p>
                        
                        <ul >
                            <li style='padding-bottom: 0px;'><i class="ri-check-double-line"></i> Free Access to all libraries</li>
                            <li style='padding-top: 0px; padding-bottom: 0px;'><i class="ri-check-double-line"></i> Borrow books from the public libraries</li>
                            <li style='padding-top: 0px;  padding-bottom: 0px;'><i class="ri-check-double-line"></i> Free Access to Exhibitions at the library </li>
                          </ul>
                        
                      </div>
                    </li>
        
                   
        
                  </ul>
                </div>
        
              </div>

    

        </div>


        <div class="col-md-4 service-menu">
            <div class="container">
                <div class="card serv">
                  <div class="header">
                    <h3>Listings</h3>
                  </div>
                  <div class="body">
                    <ul>
                    <li id='about'><i class="icofont-library ico"></i> About Us</li>
                      <li id='history'> <i class="icofont-address-book icon"></i> History and Vision</li>
                      <li id='services' class='active'><i class="icofont-institution icon" ></i>  Services</li>
                      <li id='donation'><i class="icofont-anchor icon"></i> Make a Donation</li>
                      <li id='partnership'><i class="icofont-archive icon"></i> Corporate Partnership</li>
                      <li id='foundations'><i class="icofont-badge icon"></i> Foundations, Trust and Philanthropic Support</li>
                      <li id='adopt'><i class="icofont-bars icon"></i>Adopt a Library</li>
                      <li id='coalition'><i class="icofont-contacts icon"></i> Coalition of NGOs in Library Development</li>
                      <li id='patronage'><i class="icofont-crown icon"></i> Become a Patron of Ghana Library</li>
                      <li id='membership'><i class="icofont-document-folder icon"></i> Become a Member</li>
                    </ul>
                  </div>
                </div>
              </div>
        </div>
    </div>
      
    </section><!-- End Frequently Asked Questions Section -->



 </main><!-- End #main -->