<app-alert-dialog [title]="'Alert!'" [message]="message"> </app-alert-dialog>
<app-loader></app-loader>

<section id="first" class="story" data-speed="8" data-type="background">
    <div class="smashinglogo" id="welcomeimg" data-type="sprite" data-offsetY="100" data-Xposition="50%" data-speed="-2">
        <div class="row details">



            <div class="col-md-8 details-left mob-hide">

                <div class="row">
                    <div class="col-md-1">
                        <img src="../../assets/logo/ghlaLogo.png" style='width:80px;'>

                    </div>

                    <div class="col-md-11">
                        <h1 style="font-weight: 600; color:#033600; margin-top: 30px; margin-left:20px;">GHANA LIBRARY AUTHORITY</h1>
                    </div>
                </div>



                <div class="col-md-9" style='margin-bottom:160px; margin-top:70px;'>



                    <h1 style='font-size:60px; font-weight: 600; color:#033600;'>Get an account</h1>
                    <p style='font-size:20px; line-height: 40px; color:#033600; font-weight:600; margin-top:40px;'>Create a free account to join the group of knowledge seekers nation wide. </p>

                    <div class='submit-btn' style='width:100%; margin-top:30px;'>
                        <button routerLink="/" class="btn btn-primary" style='font-weight:600; background:#033600; border-color:#033600;'><i class="fa fa-arrow-left fa-spin" aria-hidden="true"></i>
                      Home</button>
                    </div>
                </div>





            </div>



            <div class="col-md-4 details-right">
                <div class='container'>
                    <div style='width:100%; text-align:center;'>
                        <img src='../../../../../assets/logo/LOGO-TRANSPARENT.png' width='180'>
                    </div>

                    <form [formGroup]="signupForm" class="form-md">


                        <div class="form-group">
                            <table style='width:100%;'>
                                <tr>
                                    <td></td>
                                    <td>

                                        <select id='branch_id' formControlName="branch_id" class="form-control custom-select" required>
                                 <option [value]="branch.id" *ngFor="let branch of branches">{{branch.name}}</option>
                              </select>
                                        <p class="form-control-placeholder"><label for="branch_id">Select Home Library</label></p>

                                    </td>

                                </tr>
                            </table>



                        </div>
                        <div class="form-group" style='margin-top:-20px;'>
                            <table style='width:100%;'>
                                <tr>
                                    <td> </td>
                                    <td>
                                        <input id="form_name1" class="form-control" type="text" formControlName="name" required>
                                        <label class="form-control-placeholder" for="form_name1">Name</label>
                                    </td>

                                </tr>
                            </table>
                        </div>

                        <div class="form-group" style='margin-top:-20px;'>
                            <table style='width:100%;'>
                                <tr>
                                    <td></td>
                                    <td>
                                        <input id='town' formControlName="town" class="form-control" required>
                                        <label class="form-control-placeholder" for="town">City</label>
                                    </td>

                                </tr>
                            </table>
                        </div>


                        <div class="form-group" style='margin-top:-20px;'>
                            <table style='width:100%;'>
                                <tr>
                                    <td></td>
                                    <td>
                                        <input id="dob" class="form-control" type="text" formControlName="dob" onfocus="(this.type='date')" onblur="if(!this.value)this.type='text'" required>
                                        <label class="form-control-placeholder" for="dob">Date of Birth</label>
                                    </td>

                                </tr>
                            </table>
                        </div>


                        <div class="form-group" style='margin-top:-20px;'>
                            <table style='width:100%;'>
                                <tr>
                                    <td></td>
                                    <td>

                                        <select formControlName="gender" class="form-control custom-select" required>
                              <option id='gender' [value]="sex.id" *ngFor="let sex of gender">{{sex.name}}</option>
                           </select>
                                        <p class="form-control-placeholder"> <label for="gender">Gender</label></p>
                                    </td>

                                </tr>
                            </table>
                        </div>

                        <div class="form-group" style='margin-top:-20px;'>
                            <table style='width:100%;'>
                                <tr>
                                    <td></td>
                                    <td>
                                        <input id="phone" class="form-control" type="tel" formControlName="phone" pattern="\d{10}" maxlength="10" title="Please enter exactly 10 digits" required>
                                        <label class="form-control-placeholder" for="phone">Phone Number</label>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <div class="form-group" style='margin-top:-20px;'>
                            <table style='width:100%;'>
                                <tr>
                                    <td></td>
                                    <td>

                                        <select id='profession_id' formControlName="profession_id" class="form-control custom-select" required>
                              <option [value]="profession.id" *ngFor="let profession of professions">{{profession.name}}
                              </option>
                              </select>
                                        <p class="form-control-placeholder"><label for="profession_id">Profession</label></p>

                                    </td>
                                </tr>
                            </table>
                        </div>

                        <div class="form-group" style='margin-top:-20px;'>
                            <table style='width:100%;'>
                                <tr>
                                    <td></td>
                                    <td>
                                        <input id="email" class="form-control" type="email" formControlName="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" title="eg. example@example.com" required>
                                        <label class="form-control-placeholder" for="email">Email Address</label>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <div>
                            <a href='/docs/terms.html' class='terms' style="text-decoration: none; color:black;" target='_blank'>
                                <table style='width:80%; margin:-2% auto;'>

                                    <tr>
                                        <td>
                                            <label class="container-checkbox form-check-label" style='margin-top:-8px; '>
                                 <span class="checkmark"></span>
                              </label>

                                        </td>

                                        <td style='text-align:center; font-size:12px;'>
                                            I Accept Terms and Conditions, Privacy Policy

                                        </td>
                                    </tr>

                                </table>
                            </a>
                        </div>


                        <div class="terms1" style='display:none;'>

                            <table style='width:80%; margin:-2% auto;'>

                                <tr>
                                    <td>
                                        <label class="container-checkbox form-check-label" style='margin-top:-6px;'>
                                 <input type="checkbox" class='form-check-input' [checked]="termsAccepted" (change)="termsAccepted = !termsAccepted">
                                 <span class="checkmark"></span>
                              </label>

                                    </td>

                                    <td style='text-align:center; font-size:12px;'>
                                        I Accept Terms and Conditions, Privacy Policy

                                    </td>
                                </tr>

                            </table>

                        </div>



                        <div class='submit-btn'>
                            <button [disabled]="!signupForm.valid || termsAccepted == false" (click)="signup()" class="btn btn-primary" style='font-weight:600;'>SIGN UP</button>
                        </div>


                        <div style="width:100%; margin:2% auto; text-align:center;">
                            <a style='color:black;'><label class="form-check-label" for="exampleCheck1"
                     style='font-size:15px;  font-weight:600; color:rgb(145, 145, 145);'>Already a member?</label>
                  <label (click)="navigateTo('signin')" class="form-check-label" for="exampleCheck1"
                     style='margin-left:10px; font-size:18px; color:#033600; font-weight:700;cursor: pointer;'
                     routerLink="/signin">Sign In</label></a>
                        </div>

                    </form>



                </div>
            </div>

            <div class="col-md-12 mob-hide">
                <div class="footer">
                    <div class="row">
                        <div class="col-md-3">
                            <a href="/docs/privacy.html" target="_blank">
                                <h1 style='font-size:25px; font-weight: 600; color:#033600;'>Privacy Policy</h1>
                            </a>
                        </div>

                        <div class="col-md-5" style='text-align:left'>
                            <a href="/docs/terms.html" target="_blank">
                                <h1 style='font-size:25px; font-weight: 600; color:#033600;'>Terms & Conditions</h1>
                            </a>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>

</section>