<div class="modal fade" id="dialog"  tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" data-backdrop="static" data-keyboard="false" aria-hidden="true">
    <div class="modal-dialog modal-full" role="document" >
 
     
         <div class="modal-content" style='background:none; border:none;'>

<div>
    <div class="row" style="margin: 2% auto; border:none;">

        <div class="col-md-10" style='padding:10px; margin: 0% auto; background:rgb(185, 185, 185);'>
            <span id="eye" class="icon fa fa-times-circle fa-3x fa-lg backLink" style="cursor: pointer;"></span>
        </div>
        <div class="col-md-10" style='padding:0px; margin: 0% auto;' >


            <div class="containerr video-cover dialog">
                <video  width="100%" controls autoplay loop>
                    <source  src="{{videoSrc}}" />
                    Your browser doesn't support HTML5 video tag.
                </video>
            </div>
        </div>

        <div class="col-md-10" style='padding:0px; margin: 0% auto; border: 1px solid rgb(211, 211, 211); background: white;'>

        <div style='border-bottom: 1px solid black; background-color: #dfdfdf;'>
            <table id="customers">
                <thead >
                    <tr>

                        <th style='width:40%;'>{{video.title}}</th>
                        <th style='width:60%;'>Produced by: {{video.author}}</th>
                    
                    </tr>
                </thead>
            </table>
        </div>

        <div>
            <table id="customers">
                
                <tbody *ngIf="playlist.length > 0">
                    <tr  style="cursor: pointer;" (click)="videoSrc = track.url"  *ngFor="let track of playlist">

                        <td><span>{{track.title}}</span></td>
                        <td><i class="fa fa- fa-volume-up"></i></td>

                    </tr>
    
                </tbody>
            </table>
        </div>
        </div>

    </div>
</div>


</div>
</div>
</div>


<div class="row mobile" style="border:none; margin-top: -1%; display:none;">

    <div class="col-md-12" style='padding:0px; margin: 0% auto;' >


        <div class="containerr video-cover">
            <video width="100%" controls autoplay loop>
                <source src="{{videoSrc}}" />
                Your browser doesn't support HTML5 video tag.
            </video>
        </div>
    </div>

    <div class="col-md-12" style='padding:0px; margin: 0% auto; border: 1px solid rgb(211, 211, 211); background: white;'>

    <div style='border-bottom: 1px solid black; background-color: #dfdfdf; '>
        <div style='padding:20px;'>
    <p style='width:100%; font-weight: 600;'>{{video.title}}</p>
    <p style='width:100%;'>Produced by: {{video.author}}</p>     
    </div>
    </div>

    <div>
        <table id="customers">
            
            <tbody *ngIf="playlist.length > 0">
                <tr  style="cursor: pointer;" (click)="videoSrc = track.url"  *ngFor="let track of playlist">

                    <td><span>{{track.title}}</span></td>
                    <td><i class="fa fa- fa-volume-up"></i></td>

                </tr>

            </tbody>
        </table>
    </div>
    </div>

</div>