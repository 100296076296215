<div class='body'>

    <!-- ======= Hero Section ======= -->
    <div class="img_overlay">




        <div id="demo" class="carousel slide" data-ride="carousel" style='background:black;'>

            <ul class="carousel-indicators">
                <li data-target="#demo" data-slide-to="0" class="active"></li>
                <li data-target="#demo" data-slide-to="1"></li>
                <li data-target="#demo" data-slide-to="2"></li>
            </ul>
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src="../../assets/carousel/CAROUSEL1.jpg" alt="Los Angeles" width="100%">
                    <div class="carousel-caption">
                        <div class="row">
                            <div class="col-lg-12 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                                <h1>Regional Library</h1>
                                <h2> The GhLA is entering a new phase of rebirth after years of stagnation with a view to achieving growth, diversifying delivery channels, radical redesign of library services, business model & service delivery optimisation,
                                    organisational restructure and leadership transformation.</h2>
                                <div class="d-lg-flex-center">
                                    <a routerLinkActive="active" routerLink="/signin" class="btn-get-started scrollto "><i class="icofont-library"></i> Digital Library</a>
                                    <a href='https://isbn.library.gov.gh' class="btn-watch-video"> ISBN / ISSN / ISMN Purchase <i class="icofont-cart"></i></a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <img src="../../assets/carousel/CAROUSEL2.jpg" alt="Chicago" width="100%">
                    <div class="carousel-caption">
                        <div class="row">
                            <div class="col-lg-12 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                                <h1>Facilities & Booking</h1>
                                <h2> Apart from connecting Ghanaians to knowledge resources, the Ghana Library Authority has facilities( Conference hall, Board Room, Work Stations etc) that suits all occasions. Our facilities are open to organizations and
                                    individuals to host their conferences, meetings, weddings etc.</h2>
                                <div class="d-lg-flex-center">
                                    <a routerLinkActive="active" routerLink="/signin" class="btn-get-started scrollto"><i class="icofont-library"></i> Digital Library</a>
                                    <a href='https://isbn.library.gov.gh' class="btn-watch-video"> ISBN / ISSN / ISMN Purchase <i class="icofont-cart"></i></a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <img src="../../assets/carousel/CAROUSEL4.jpg" alt="New York" width="100%">
                    <div class="carousel-caption">
                        <div class="row">
                            <div class="col-lg-12 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                                <h1>Anniversary!</h1>
                                <h2> Ghana Library Authority marks 2020 as its 70th Anniversary.</h2>
                                <div class="d-lg-flex-center">
                                    <a routerLinkActive="active" routerLink="/signin" class="btn-get-started scrollto"><i class="icofont-library"></i> Digital Library</a>
                                    <a href='https://isbn.library.gov.gh' class="btn-watch-video"> ISBN / ISSN / ISMN Purchase <i class="icofont-cart"></i></a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
    <!-- End Hero -->

    <main id="main">

        <!-- ======= Cliens Section ======= -->
        <section id="cliens" class="cliens section-bg">
            <div class="container">

                <div class="row" data-aos="zoom-in">

                    <div class="owl-carousel owl-theme">
                        <div class="item"><img src="../../../../assets/partners/newmont.png" class="img-fluid" alt=""></div>
                        <div class="item"><img src="../../../../assets/partners/gifec.png" class="img-fluid" alt=""></div>
                        <div class="item"><img src="../../../../assets/partners/vodafone.png" class="img-fluid" alt=""></div>
                        <div class="item"><img src="../../../../assets/partners/bookaid.png" class="img-fluid" alt=""></div>
                        <div class="item"><img src="../../../../assets/partners/worldreader.jpg" class="img-fluid" alt=""></div>
                        <div class="item"><img src="../../../../assets/partners/eifl.jpeg" class="img-fluid" alt=""></div>
                        <div class="item"><img src="../../../../assets/partners/surfline.png" class="img-fluid" alt=""></div>
                        <div class="item"><img src="../../../../assets/partners/worldvision.jpg" class="img-fluid" alt=""></div>
                        <div class="item"><img src="../../../../assets/partners/unisef.png" class="img-fluid" alt=""></div>

                    </div>




                </div>

            </div>
        </section>
        <!-- End Cliens Section -->

        <!-- ======= About Us Section ======= -->
        <section id="about" class="about" style='padding:0px;'>
            <div class="" data-aos="fade-up">
                <!-- <div class="balloon"></div>
        <div class="balloon"></div>
        <div class="balloon"></div>
        <div class="balloon"></div>
        <div class="balloon"></div> -->
                <div class="row content">
                    <div class="col-lg-5" style='padding:80px;font-size:16px; background: rgba(255, 255, 255, 0.781);'>
                        <div class="section-title">
                            <h2>Welcome</h2>
                        </div>

                        <p style='line-height:30.5px;'>The Ghana Library Authority (GhLA) was established in 1949 by the Gold Coast Ordinance CAP 118 and came into effect in January, 1950. The Ordinance was re-enacted as the Ghana Library Board Act, 1970 (Act 327). Act 327 was also
                            amended by Act 562 of 1998 for the Ghana Library Board to become Ghana Library Authority. The mandate of the Ghana Library Authority is to establish, equip, maintain and manage public libraries in Ghana.</p>
                    </div>


                    <div class="col-lg-2">

                        <div class="row">
                            <div class="col-md-12 welcome-total" style='background:rgb(95, 160, 69);'>
                                <span style='color:white; margin-bottom:50px;'>eBooks</span>
                                <p style='margin-top:13px; font-weight:600;' class='total_ebook'><strong class="fact-counter count total_ebook">4023</strong></p>
                            </div>
                            <div class="col-md-12 welcome-total" style='background:rgb(83, 198, 202);'>
                                <span style='color:white'>eAudio</span>
                                <p style='margin-top:13px; font-weight:600;' class='total_audio'><strong class="fact-counter count total_audio">13622</strong></p>
                            </div>
                            <div class="col-md-12 welcome-total" style='background:rgb(219, 89, 89);'>
                                <span style='color:white'>Hard copy books</span>
                                <p style='margin-top:13px; font-weight:600;' class="total_hardcopy"><strong class="fact-counter count total_hardcopy">1190576</strong></p>
                            </div>
                            <div class="col-md-12 welcome-total" style='background:rgb(89, 156, 219);'>
                                <span style='color:white'>Videos</span><br>
                                <p style='margin-top:13px; font-weight:600;' class='total_video'><strong class="fact-counter count total_video">1500</strong></p>
                            </div>
                        </div>
                    </div>

                    <div class="welcome-back col-lg-5"></div>
                </div>

            </div>
        </section>
        <!-- End About Us Section -->




        <!-- ======= Portfolio Section ======= -->
        <section id="portfolio" class="portfolio">
            <div class="" data-aos="fade-up">

                <div class="section-title">
                    <h2>Check out our new releases</h2>
                    <p>Great people are great readers; explore the Ghana Library Authority catalog of new releases.</p>
                </div>

                <div class="row">

                    <div class="col-md-11" style='text-align:left'>
                        <p style='font-weight: 500;'>Recently Added</p>
                    </div>




                    <div class="col-md-1">
                        <table style="margin-left:30%;">
                            <tr>
                                <td>
                                    <div class='div'>

                                        <a class='seek horizon-prev'>
                                            <i class="icofont-simple-left" style='font-size:25px;'></i>
                                        </a>
                                    </div>
                                </td>
                                <td>
                                    <div class='div'>

                                        <a class='seek horizon-next'>
                                            <i class="icofont-simple-right" style='font-size:25px;'></i>

                                        </a>
                                    </div>
                                </td>
                            </tr>
                        </table>

                    </div>

                </div>



                <div class="row recent showcase row-container" data-aos="fade-up" data-aos-delay="200" style=' padding-bottom:30px;'>

                    <div class="col-md-12 contenttt" style='box-shadow:  0px 2px rgb(228, 228, 228);'>

                        <div class="center" id="content" style='overflow: scroll;  padding: 0px; '>
                            <div id="grid" class="row-fluid ">

                                <table style='max-width:100%; '>
                                    <tr class="e-file-loader e-file-loader0">
                                    </tr>
                                </table>

                                <div (click)="showDetails(ebook)" *ngFor="let ebook of recentEbooks" style="cursor: pointer; " class="mix col-sm-2 page1 page4 margin30 content">

                                    <div class="item-img-wrap" [ngStyle]="{'background-image':'url('+ebook.cover+')'}">

                                        <div class="item-img-overlay">
                                            <a class="show-image">
                                                <span></span>
                                            </a>

                                        </div>


                                    </div>

                                    <div>
                                        <h1 class='a' style='color:black; font-weight:600; font-size:17px;'>{{ebook.title}}</h1>
                                    </div>
                                    <div><a class='a' style='color:rgb(122, 122, 122); margin-top:-20px;'>{{ebook.author}}</a></div>
                                    <div><a class='a' style='color:rgb(122, 122, 122); margin-top:0px;'>E-book</a></div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>








                <div class="row">
                    <div class="col-md-11" style='text-align:left'>
                        <p style='font-weight: 500;'>Popular E-books</p>
                    </div>

                    <div class="col-md-1">
                        <table style="margin-left:30%;">
                            <tr>
                                <td>
                                    <div class='div'>

                                        <a class='seek horizon-prev-ebook'>
                                            <i class="icofont-simple-left" style='font-size:25px;'></i>
                                        </a>
                                    </div>
                                </td>
                                <td>
                                    <div class='div'>

                                        <a class='seek horizon-next-ebook'>
                                            <i class="icofont-simple-right" style='font-size:25px;'></i>

                                        </a>
                                    </div>
                                </td>
                            </tr>
                        </table>

                    </div>

                </div>


                <div class="row ebooks showcase" data-aos="fade-up" data-aos-delay="200" style=' padding-bottom:30px;'>
                    <div class="col-md-12" style='box-shadow:  0px 2px rgb(228, 228, 228);'>
                        <div class="center" id="content-ebook" style='overflow: scroll;  padding: 0px; '>
                            <div id="grid" class="row-fluid">


                                <table style='max-width:100%; '>
                                    <tr class="e-file-loader e-file-loader1">
                                    </tr>
                                </table>


                                <div (click)="showDetails(ebook)" *ngFor="let ebook of popularEbooks" style="cursor: pointer;" class="mix col-sm-2 page1 page4 margin30">
                                    <div class="item-img-wrap " [ngStyle]="{'background-image':'url('+ebook.cover+')'}">
                                        <div class="item-img-overlay">
                                            <a class="show-image">
                                                <span></span>
                                            </a>
                                        </div>


                                    </div>
                                    <div>
                                        <h1 class='a' style='color:black; font-weight:600; font-size:17px;'>{{ebook.title}}</h1>
                                    </div>
                                    <div><a class='a' style='color:rgb(122, 122, 122); margin-top:-20px;'>{{ebook.author}}</a></div>
                                    <div><a class='a' style='color:rgb(122, 122, 122); margin-top:0px;'>E-book</a></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>





                <div class="row">
                    <div class="col-md-11" style='text-align:left'>
                        <p style='font-weight: 500;'>Popular Audio books</p>
                    </div>

                    <div class="col-md-1">
                        <table style="margin-left:30%;">
                            <tr>
                                <td>
                                    <div class='div'>

                                        <a class='seek horizon-prev-audio'>
                                            <i class="icofont-simple-left" style='font-size:25px;'></i>
                                        </a>
                                    </div>
                                </td>
                                <td>
                                    <div class='div'>

                                        <a class='seek horizon-next-audio'>
                                            <i class="icofont-simple-right" style='font-size:25px;'></i>

                                        </a>
                                    </div>
                                </td>
                            </tr>
                        </table>

                    </div>



                </div>

                <div class="row eaudio showcase" data-aos="fade-up" data-aos-delay="200" style=' padding-bottom:30px;'>
                    <div class="col-md-12" style='box-shadow:  0px 2px rgb(228, 228, 228);'>
                        <div class="center" id="content-audio" style='overflow: scroll;  padding: 0px; '>
                            <div id="grid" class="row-fluid">



                                <table style='max-width:100%; '>
                                    <tr class="e-file-loader e-file-loader2">
                                    </tr>
                                </table>


                                <div (click)="showAudiobook(audiobook)" *ngFor="let audiobook of popularAudiobooks" style="cursor: pointer;" class="mix col-sm-2 page1 page4 margin30">

                                    <div class="item-img-wrap " [ngStyle]="{'background-image':'url('+audiobook.cover+')'}">
                                        <div class="loader">
                                            <svg id="wave" data-name="Layer 1" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 50 38.05">
                                      <title>Audio Wave</title>
                                      <path id="Line_1" data-name="Line 1" d="M0.91,15L0.78,15A1,1,0,0,0,0,16v6a1,1,0,1,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H0.91Z"/>
                                      <path id="Line_2" data-name="Line 2" d="M6.91,9L6.78,9A1,1,0,0,0,6,10V28a1,1,0,1,0,2,0s0,0,0,0V10A1,1,0,0,0,7,9H6.91Z"/>
                                      <path id="Line_3" data-name="Line 3" d="M12.91,0L12.78,0A1,1,0,0,0,12,1V37a1,1,0,1,0,2,0s0,0,0,0V1a1,1,0,0,0-1-1H12.91Z"/>
                                      <path id="Line_4" data-name="Line 4" d="M18.91,10l-0.12,0A1,1,0,0,0,18,11V27a1,1,0,1,0,2,0s0,0,0,0V11a1,1,0,0,0-1-1H18.91Z"/>
                                      <path id="Line_5" data-name="Line 5" d="M24.91,15l-0.12,0A1,1,0,0,0,24,16v6a1,1,0,0,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H24.91Z"/>
                                    </svg>
                                        </div>

                                        <div class="item-img-overlay">
                                            <a class="show-image">
                                                <span></span>
                                            </a>
                                        </div>
                                    </div>
                                    <div>
                                        <h1 class='a' style='color:black; font-weight:600; font-size:17px;'>{{audiobook.title}}</h1>
                                    </div>
                                    <div><a class='a' style='color:rgb(122, 122, 122); margin-top:-20px;'>{{audiobook.author}}</a></div>
                                    <div><a class='a' style='color:rgb(122, 122, 122); margin-top:0px;'>Audio Book</a></div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>






                <div class="row">
                    <div class="col-md-11" style='text-align:left'>
                        <p style='font-weight: 500;'>Popular Videos</p>
                    </div>

                    <div class="col-md-1" style="text-align:right;">
                        <table style="margin-left:30%;">
                            <tr>
                                <td style="text-align:right;">
                                    <div class='div'>

                                        <a class='seek horizon-prev-video'>
                                            <i class="icofont-simple-left" style='font-size:25px;'></i>
                                        </a>
                                    </div>
                                </td>
                                <td style="text-align:right">
                                    <div class='div'>

                                        <a class='seek horizon-next-video'>
                                            <i class="icofont-simple-right" style='font-size:25px;'></i>

                                        </a>
                                    </div>
                                </td>
                            </tr>
                        </table>

                    </div>



                </div>
                <div class="row evideo showcase">
                    <div class="col-md-12" style='box-shadow:  0px 2px rgb(228, 228, 228);'>
                        <div class="center" id="content-video" style='overflow: scroll;  padding: 0px; '>
                            <div id="grid" class="row-fluid">


                                <table style='max-width:100%; '>
                                    <tr class="e-file-loader e-file-loader3">
                                    </tr>
                                </table>

                                <div (click)="showVideo(video)" *ngFor="let video of popularVideos" style="cursor: pointer;" class="mix col-sm-5 page1 page4 margin30">

                                    <div class="item-img-wrap " [ngStyle]="{'background-image':'url('+video.cover+')'}">

                                        <div class="loader">

                                            <i class='icofont-play-alt-2' style='margin-top:0px; font-size:80px; color: rgb(221, 221, 221)'></i>
                                        </div>
                                        <div class="item-img-overlay">
                                            <a class="show-image">
                                                <span></span>
                                            </a>
                                        </div>
                                    </div>
                                    <div>
                                        <h1 class='a' style='color:black; font-weight:600; font-size:17px;'>{{video.title}}</h1>
                                    </div>
                                    <div><a class='a' style='color:rgb(122, 122, 122); margin-top:-20px;'>{{video.author}}</a></div>
                                    <div><a class='a' style='color:rgb(122, 122, 122); margin-top:0px;'>Video</a></div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>







            </div>
        </section>
        <!-- End Portfolio Section -->

        <!-- ======= Team Section ======= -->
        <section id="team" class="team section-bg" style='display:none;'>
            <div class="container" data-aos="fade-up">

                <div class="section-title">
                    <h2>Team</h2>
                    <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi
                        quidem hic quas.</p>
                </div>

                <div class="row">

                    <div class="col-lg-6">
                        <div class="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="100">
                            <div class="pic"><img src="assets/img/team/team-1.jpg" class="img-fluid" alt=""></div>
                            <div class="member-info">
                                <h4>Walter White</h4>
                                <span>Chief Executive Officer</span>
                                <p>Explicabo voluptatem mollitia et repellat qui dolorum quasi</p>
                                <div class="social">
                                    <a href=""><i class="ri-twitter-fill"></i></a>
                                    <a href=""><i class="ri-facebook-fill"></i></a>
                                    <a href=""><i class="ri-instagram-fill"></i></a>
                                    <a href=""> <i class="ri-linkedin-box-fill"></i> </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 mt-4 mt-lg-0">
                        <div class="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="200">
                            <div class="pic"><img src="assets/img/team/team-2.jpg" class="img-fluid" alt=""></div>
                            <div class="member-info">
                                <h4>Sarah Jhonson</h4>
                                <span>Product Manager</span>
                                <p>Aut maiores voluptates amet et quis praesentium qui senda para</p>
                                <div class="social">
                                    <a href=""><i class="ri-twitter-fill"></i></a>
                                    <a href=""><i class="ri-facebook-fill"></i></a>
                                    <a href=""><i class="ri-instagram-fill"></i></a>
                                    <a href=""> <i class="ri-linkedin-box-fill"></i> </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 mt-4">
                        <div class="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="300">
                            <div class="pic"><img src="assets/img/team/team-3.jpg" class="img-fluid" alt=""></div>
                            <div class="member-info">
                                <h4>William Anderson</h4>
                                <span>CTO</span>
                                <p>Quisquam facilis cum velit laborum corrupti fuga rerum quia</p>
                                <div class="social">
                                    <a href=""><i class="ri-twitter-fill"></i></a>
                                    <a href=""><i class="ri-facebook-fill"></i></a>
                                    <a href=""><i class="ri-instagram-fill"></i></a>
                                    <a href=""> <i class="ri-linkedin-box-fill"></i> </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 mt-4">
                        <div class="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="400">
                            <div class="pic"><img src="assets/img/team/team-4.jpg" class="img-fluid" alt=""></div>
                            <div class="member-info">
                                <h4>Amanda Jepson</h4>
                                <span>Accountant</span>
                                <p>Dolorum tempora officiis odit laborum officiis et et accusamus</p>
                                <div class="social">
                                    <a href=""><i class="ri-twitter-fill"></i></a>
                                    <a href=""><i class="ri-facebook-fill"></i></a>
                                    <a href=""><i class="ri-instagram-fill"></i></a>
                                    <a href=""> <i class="ri-linkedin-box-fill"></i> </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </section>
        <!-- End Team Section -->

        <!-- ======= Pricing Section ======= -->
        <section id="pricing" class="pricing purchases">
            <div class="container" data-aos="fade-up">

                <div class="section-title">
                    <h2 style='color:white;'>International Standard Numbers Pricing</h2>
                    <p>An ISNS is essentially a unique identifier for books, notated music publications, title of serial publications and is used by publishers, booksellers, libraries, internet retailers and other supply chain participants for ordering,
                        listing, sales records and stock control purposes.</p>
                </div>

                <div class="row">

                    <div class="col-lg-3" data-aos="fade-up" data-aos-delay="100">
                        <div class="box featured">
                            <h3>ISBN</h3>
                            <div class="row" style='margin-top:-40px;'>
                                <div class="col-md-6">
                                    <h4><sup>&#8373;</sup>30 <span>per number</span></h4>
                                </div>

                                <div class="col-md-6">
                                    <h4><sup>&#8373;</sup>200 <span>for 10 numbers</span></h4>
                                </div>
                            </div>

                            <a href="https://isbn.library.gov.gh" class="buy-btn">Get an ISBN</a>
                        </div>


                    </div>

                    <div class="col-lg-3 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="200">
                        <div class="box featured">
                            <h3>ISSN</h3>
                            <h4><sup>&#8373;</sup>150 <span>per number</span></h4>
                            <a href="https://isbn.library.gov.gh" class="buy-btn">Get an ISSN</a>
                        </div>
                    </div>

                    <div class="col-lg-3 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="300">
                        <div class="box featured">
                            <h3>ISMN</h3>
                            <h4><sup>&#8373;</sup>100 <span>for 10 numbers</span></h4>
                            <a href="https://isbn.library.gov.gh" class="buy-btn">Get an ISMN</a>
                        </div>
                    </div>

                    <div class="col-lg-3 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="300">
                        <div class="box featured">
                            <h3>CIP</h3>
                            <h4><sup>&#8373;</sup>20<span>per title</span></h4>
                            <a href="https://isbn.library.gov.gh" class="buy-btn">Apply</a>
                        </div>
                    </div>

                </div>

            </div>
        </section>
        <!-- End Pricing Section -->


        <!-- ======= Services Section ======= -->
        <section id="services" class="services section-bg">

            <section class="wrapper">
                <div class="container-fostrap" data-aos="fade-up">

                    <div class="section-title">
                        <h2>News & Events</h2>
                        <p>The Ghana Library Authority.</p>
                    </div>
                    <div class="content">
                        <div class="container">
                            <div class="row">
                                <div class="col-xs-12 col-sm-6">
                                    <div class="card">
                                        <div class="card-header">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    NEWS
                                                </div>

                                                <div class="col-md-8">
                                                    <p style='width:100%; background: #ffb200; height: 1px; margin-top: 8px;'></p>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="card-content">
                                            <h4 class="card-title">
                                                <a style='color:rgb(59, 59, 59); font-size: 20px;' routerLink="/">{{article1.title}}</a>
                                            </h4>

                                            <p> <i class="fa fa-calendar"></i> {{article1.date}}</p>


                                            <p class="" style='color:rgb(102, 102, 102); '>
                                                {{article1.description}}
                                            </p>
                                        </div>
                                        <div class="card-read-more">
                                            <a (click)="viewArticleId(article1.id)" class="btn btn-link btn-block">
                                            Read More
                                        </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6">
                                    <div class="card">
                                        <div class="card-header">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    NEWS
                                                </div>

                                                <div class="col-md-8">
                                                    <p style='width:100%; background: #ffb200; height: 1px; margin-top: 8px;'></p>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="card-content">
                                            <h4 class="card-title">
                                                <a style='color:rgb(59, 59, 59); font-size: 20px;' routerLink="/">{{article2.title}}</a>
                                            </h4>
                                            <p><i class="fa fa-calendar"></i> {{article2.date}}</p>
                                            <p class="" style='color:rgb(102, 102, 102); '>
                                                {{article2.description}}
                                            </p>
                                        </div>
                                        <div class="card-read-more">
                                            <a (click)="viewArticleId(article2.id)" class="btn btn-link btn-block">
                                            Read More
                                        </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6">
                                    <div class="card">
                                        <div class="card-header">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    NEWS
                                                </div>

                                                <div class="col-md-8">
                                                    <p style='width:100%; background: #ffb200; height: 1px; margin-top: 8px;'></p>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="card-content">
                                            <h4 class="card-title">
                                                <a style='color:rgb(59, 59, 59); font-size: 20px;' routerLink="/">{{article3.title}}</a>
                                            </h4>
                                            <p><i class="fa fa-calendar"></i> {{article3.date}}</p>
                                            <p class="" style='color:rgb(102, 102, 102); '>
                                                {{article3.description}}
                                            </p>
                                        </div>
                                        <div class="card-read-more">
                                            <a (click)="viewArticleId(article3.id)" class="btn btn-link btn-block">
                                            Read More
                                        </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6">
                                    <div class="card">
                                        <div class="card-header">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    NEWS
                                                </div>

                                                <div class="col-md-8">
                                                    <p style='width:100%; background: #ffb200; height: 1px; margin-top: 8px;'></p>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="card-content">
                                            <h4 class="card-title">
                                                <a style='color:rgb(59, 59, 59); font-size: 20px;' routerLink="/">{{article4.title}}</a>
                                            </h4>
                                            <p><i class="fa fa-calendar"></i> {{article4.date}}</p>
                                            <p class="" style='color:rgb(102, 102, 102); '>
                                                {{article4.description}}
                                            </p>
                                        </div>
                                        <div class="card-read-more">
                                            <a (click)="viewArticleId(article4.id)" class="btn btn-link btn-block">
                                            Read More
                                        </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </section>
        <!-- End Services Section -->


        <!-- ======= Contact Section ======= -->

        <!-- <section id="contact" class="contact">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Contact</h2>
          <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
        </div>

        <div class="row">

          <div class="col-lg-5 d-flex align-items-stretch">
            <div class="info">
              <div class="address">
                <i class="icofont-google-map"></i>
                <h4>Location:</h4>
                <p>A108 Adam Street, New York, NY 535022</p>
              </div>

              <div class="email">
                <i class="icofont-envelope"></i>
                <h4>Email:</h4>
                <p>info@example.com</p>
              </div>

              <div class="phone">
                <i class="icofont-phone"></i>
                <h4>Call:</h4>
                <p>+1 5589 55488 55s</p>
              </div>

              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621" frameborder="0" style="border:0; width: 100%; height: 290px;" allowfullscreen></iframe>
            </div>

          </div>

          <div class="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
            <form action="forms/contact.php" method="post" role="form" class="php-email-form">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="name">Your Name</label>
                  <input type="text" name="name" class="form-control" id="name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                  <div class="validate"></div>
                </div>
                <div class="form-group col-md-6">
                  <label for="name">Your Email</label>
                  <input type="email" class="form-control" name="email" id="email" data-rule="email" data-msg="Please enter a valid email" />
                  <div class="validate"></div>
                </div>
              </div>
              <div class="form-group">
                <label for="name">Subject</label>
                <input type="text" class="form-control" name="subject" id="subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
                <div class="validate"></div>
              </div>
              <div class="form-group">
                <label for="name">Message</label>
                <textarea class="form-control" name="message" rows="10" data-rule="required" data-msg="Please write something for us"></textarea>
                <div class="validate"></div>
              </div>
              <div class="mb-3">
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div class="text-center"><button type="submit">Send Message</button></div>
            </form>
          </div>

        </div>

      </div>
    </section> -->

        <!-- End Contact Section -->

    </main>
    <!-- End #main -->
</div>