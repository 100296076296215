

<main id="main">

    

     <!-- ======= Pricing Section ======= -->
     <section id="pricing" class="pricing news">
        <div class="container" data-aos="fade-up" style='height:250px;'>
  
         
  
        </div>
      </section><!-- End Pricing Section -->
  <!-- ======= Breadcrumbs ======= -->
  <section id="breadcrumbs" class="breadcrumbs">
    <div class="container" data-aos="fade-up">

      <ol>
        <li><a routerLink='/'>Home</a></li>
        <li>Read News</li>
      </ol>
      <h2>News Details</h2>

    </div>
  </section><!-- End Breadcrumbs -->

    <!-- ======= Portfolio Details Section ======= -->
    <section id="portfolio-details" class="portfolio-details">
      <div class="container" data-aos="fade-up">

    

        <div class="portfolio-description">
          <h2 style='width:100%; color:rgb(59, 59, 59); font-size: 20px;'>{{singlenews.title}}</h2>

          <ol>
            <li><i class="fa fa-calendar"></i> {{singlenews.date}}</li>
            <li><i class="fa fa-clock-o"></i> 10:15 AM - 10:15 PM </li>
            <li><i class="fa fa-map-marker"></i>  Accra Central, Ghana </li>
          </ol>
          <p style='line-height:40px; font-size:18px; color:rgb(107, 106, 106);' [innerHTML]="singlenews.article">
           
          </p>
        </div>

      </div>
    </section><!-- End Portfolio Details Section -->

  </main><!-- End #main -->