<div class='body'>
    <div class="mobile-nav-overly"> <button type="button" class="mobile-nav-toggle mobile-nav-toggle1 d-lg-none"><i class="icofont-navigation-menu"></i></button></div>
    <nav class='mobile-nav  d-lg-none'>
        <ul>
            <li class="home menui"><a routerLink='/'>Home</a></li>
            <li><a href="https://gnb.library.gov.gh">GNB</a></li>
            <li><a href="https://ils.ghanalibrary.org" target="_blank">Catalog</a></li>
            <li class='events menui' [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}"><a routerLink="/Newsevents">News & Events</a></li>
            <li class='drop-down'><a>Programmes</a>
                <ul>
                    <!-- <li><a href='https://docs.google.com/forms/d/e/1FAIpQLSdMICagbXI65QiSor0r46D35veEtAqCOIXPJawga_v6Qn2odw/viewform' target="_blank">GhLA Scholastic</a></li> -->
                    <!-- <li><a href='https://ghanalibrary.org/library/shortstorychallenge/' target="_blank">National short story writing challenge</a></li> -->
                    <li><a href='https://edmodo.com.gh' target="_blank">Edmodo Ghana</a></li>
                    <li><a href='/en/enterpreneurshipreimagine/' target="_blank">Entrepreneurship Re-imagine</a></li>
                    <li><a href='https://robotics.ghanalibrary.org' target="_blank">Robotics Lessons</a></li>
                    <li><a href='/en/shortstorychallenge/'>National Short Story Writing Challenge</a></li>
                    <li><a href='/en/creativewritingworkshop'>Creative Writing Workshop</a></li>
                    <li><a href='/en/waecnovdecregistration/'>WAEC Biometric Registration</a></li>
                    <li><a href='/en/onlineChessTraining/'>Online Chess Training</a></li>
                    <li><a href='/en/scholastic/'>Scholastic Litracy Pro</a></li>
                    <li><a href='/en/digitalreadingchallenge/'>Digital Reading Challenge</a></li>
                    <li><a href='/en/advocacy/'>Youth Advocacy Challenge</a></li>
                    <li><a href='/en/creativeartsclass/' target="_blank">Creative Arts Class</a></li>
                    <!-- <li><a href="https://forms.gle/vSCiG4x26EpykqNt6" target="_blank">YEC Registration</a></li> -->
                    <li><a href="/en/yec-advisory-board/">YEC Advisory Board</a></li>
                    <li class="drop-down">
                        <a href="#" style='border-bottom:none;'>Read2skill</a>
                        <ul>
                            <li><a class="active" href="/en/readytowork/">Ready To Work</a></li>
                            <li><a href="/en/read2skillgoogleedition/">Google Edition</a></li>

                        </ul>

                    </li>
                </ul>
            </li>
            <li class="drop-down"><a>Purchases</a>
                <ul>
                    <li><a href='https://isbn.library.gov.gh' style='margin-left:10px;'>ISBN Purchase</a></li>
                    <li><a href='https://isbn.library.gov.gh'>ISMN Purchase</a></li>
                    <li><a href='https://isbn.library.gov.gh'>ISSN Purchase</a></li>
                    <li><a href='https://isbn.library.gov.gh'>CIP Purchase</a></li>
                </ul>
            </li>
            <li class='bf menui'><a routerLink="/BookingFacilities">Booking & Facilities</a></li>
            <li class='lf menui' [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}"><a routerLink="/LocationFinder">Location Finder</a></li>
            <li><a href='/en/annual-reports/'>Document Trail</a></li>
            <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}" class='ab menui'><a routerLink="/About">About</a></li>


        </ul>

    </nav>

    <!-- ======= Header ======= -->
    <header id="header" class="fixed-top ">
        <div class="d-flex align-items-center">
            <h3 class="logo mr-auto name" style='margin-left:40px;' title='Ghana Library Authority'>
                <a routerLink="/" style='font-size:100%;'><img src="../../assets/logo/ghlaLogo.png"> GhLA</a>
            </h3>
            <h3 class="logo mr-auto shortened" style='margin-left:10px;'>
                <a routerLink="/"><img src="../../assets/logo/ghlaLogo.png"> GhLA</a>
            </h3>
            <!-- Uncomment below if you prefer to use an image logo -->
            <!--adasdsa <a href="index.html" class="logo mr-auto"><img src="../../assets/logo/ghlaLogo.png" alt="" class="img-fluid"></a>-->


            <nav class="nav-menu d-none d-lg-block">
                <ul>
                    <li class="home menui"><a routerLink='/'>Home</a></li>
                    <li><a href="https://gnb.library.gov.gh">GNB</a></li>
                    <li><a href="https://ils.ghanalibrary.org" target="_blank">Catalog</a></li>
                    <li class='events menui' [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}"><a routerLink="/Newsevents">News & Events</a></li>
                    <li class='drop-down'><a>Programmes</a>
                        <ul>
                            <li><a href='https://edmodo.com.gh' style='margin-left:10px;' target="_blank">Edmodo Ghana</a></li>
                            <li><a href='/en/enterpreneurshipreimagine/' target="_blank">Entrepreneurship Re-imagine</a></li>
                            <li><a href='https://robotics.ghanalibrary.org' target="_blank">Robotics Lessons</a></li>
                            <li><a href='/en/shortstorychallenge/'>National Short Story Writing Challenge</a></li>
                            <li><a href='/en/creativewritingworkshop'>Creative Writing Workshop</a></li>
                            <li><a href='/en/waecnovdecregistration/'>WAEC Biometric Registration</a></li>
                            <li><a href='/en/onlineChessTraining/'>Online Chess Training</a></li>
                            <li><a href='/en/scholastic/'>Scholastic Litracy Pro</a></li>
                            <li><a href='/en/digitalreadingchallenge/'>Digital Reading Challenge</a></li>
                            <li><a href='/en/advocacy/'>Youth Advocacy Challenge</a></li>
                            <li style='margin-left:-10px;'><a href='/en/creativeartsclass/' style='margin-left:10px;' target="_blank">Creative Arts Class</a></li>
                            <!-- <li><a href="https://forms.gle/vSCiG4x26EpykqNt6" target="_blank">YEC Registration</a></li> -->
                            <li><a href="/en/yec-advisory-board/">YEC Advisory Board</a></li>
                            <li class="drop-down">
                                <a href="#" style='border-bottom:none;'>Read2skill</a>
                                <ul>
                                    <li style='margin-left:10px;'><a style='border-bottom:none;' href="/en/readytowork/">ReadyToWork</a></li>
                                    <li><a href="/en/read2skillgoogleedition/">Google Edition</a></li>
                                    <li><a href="https://forms.gle/7iBnWgh3VCsDoSKg7" target="_blank">Udemy</a></li>
                                    <li><a href="https://forms.gle/7iBnWgh3VCsDoSKg7" target="_blank">Coursera</a></li>
                                </ul>

                            </li>
                        </ul>
                    </li>
                    <li class="drop-down"><a>Purchases</a>
                        <ul>
                            <li style='margin-left:10px;'><a href='https://isbn.library.gov.gh/'>ISBN Purchase</a></li>
                            <li><a href='https://isbn.library.gov.gh/'>ISMN Purchase</a></li>
                            <li><a href='https://isbn.library.gov.gh/'>ISSN Purchase</a></li>
                            <li><a href='https://isbn.library.gov.gh/'>CIP Purchase</a></li>
                        </ul>
                    </li>
                    <li class='bf menui'><a routerLink="/BookingFacilities">Booking & Facilities</a></li>
                    <li class='lf menui' [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}"><a routerLink="/LocationFinder">Location Finder</a></li>
                    <li><a href='/en/annual-reports/'>Document Trail</a></li>
                    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}" class='ab menui'><a routerLink="/About">About</a></li>

                </ul>
            </nav>
            <!-- .nav-menu -->


            <!--- <nav class="nav-menu d-none d-lg-block">
        <ul>
          <li class="home menui"><a routerLink='/'>Home</a></li>
          <li><a href= "https://ils.ghanalibrary.org" target="_blank">Catalog</a></li>
          <li class='events menui' [routerLinkActive]="['active']" [routerLinkActiveOptions] = "{exact: true}"><a routerLink="/Newsevents">News & Events</a></li>
          <li class='drop-down'><a >Programmes</a>
            <ul>
                <li><a href='https://docs.google.com/forms/d/e/1FAIpQLSdMICagbXI65QiSor0r46D35veEtAqCOIXPJawga_v6Qn2odw/viewform' target="_blank">GhLA Scholastic</a></li>
                <li><a href='https://ghanalibrary.org/library/shortstorychallenge/' target="_blank">National short story writing challenge</a></li>
                <li><a href='https://ghanalibrary.org/library/creativeartsclass/' target="_blank">Creative Arts Class</a></li>
                <li><a href='https://ghanalibrary.org/library/read2skill/' target="_blank">Read to skill</a></li>
              </ul>
        </li>
          <li class="drop-down" ><a>Purchases</a>
            <ul>
              <li><a  href='javascript:' >ISBN Purchase</a></li>
              <li><a  href='https://library.gov.gh/ismn/' >ISSN Purchase</a></li>
              <li><a  href='javascript:' >ISSN Purchase</a></li>
              <li><a  href='javascript:' >CIP Purchase</a></li>
            </ul>
          </li>
          <li class='bf menui'><a routerLink="/BookingFacilities">Booking & Facilities</a></li>
          <li class='lf menui' [routerLinkActive]="['active']" [routerLinkActiveOptions] = "{exact: true}"><a routerLink="/LocationFinder"  >Location Finder</a></li>
          <li [routerLinkActive]="['active']" [routerLinkActiveOptions] = "{exact: true}" class='ab menui'><a routerLink="/About">About</a></li>

        </ul>
      </nav>-->
            <!-- .nav-menu -->

            <a routerLinkActive="active" routerLink="/signin" class="get-started-btn scrollto name"><i class="icofont-library"></i> Digital Library</a>
            <a routerLinkActive="active" routerLink="/signin" class="get-started-btn scrollto shortened"><i class="icofont-library"></i> Digi Library</a>
            <button type="button" class="mobile-nav-toggle d-lg-none"><i class="icofont-navigation-menu"></i></button>
        </div>


    </header>
    <!-- End Header -->

    <router-outlet></router-outlet>

    <section>

        <div class='footerr'>

            <div id="crossfade">
                <img src="assets/img/mobile_library.png" alt="Image 1">
                <img src="assets/img/ghla_70.jpg" alt="Image 2">
                <img src="assets/img/conference.png" alt="Image 3">
                <img src="assets/img/endowmentfund.png" alt="Image 4">
				<!-- <img src="assets/img/job-vacancy-announcement.jpg" alt="Image 1">
				<img src="assets/img/hire.png" alt="Image 2"> -->
            </div>
            <a href="assets/brochure/MOBILE LIBRARY END OF PROJECT REPORT - 2021.pdf" target="_blank" class="btn btn-primary button" style='margin-left:20px; background:#033600; border:#033600;'>
				View Our Mobile Library End Of Project Report - 2021.pdf!!!
			</a>
			<!-- <a href="assets/docs/vacancy-announcement-2023.pdf" target="_blank" class="btn btn-primary button" style='margin-left:20px; background:#033600; border:#033600;'>
				Vacancy Announcement!
			</a> -->
            <!-- <div style='background:white; width:400px;'>
                <a href="assets/brochure/brochure.pdf" target="_blank">
                    <h1 class='four'><span>Download our 70th Anniversary Brochure!!!</span></h1>
                </a>
            </div>
            <img src="assets/img/ghanalibrary_70.jpg" width="200" style='margin-left:50px;'>
            <div style="font-size: 12pt; margin-bottom: 20px; clear: both;"> hugygt-->
            <!-- <div class="speech onLeft ">Here is my example of a speech bubble created in CSS and HTML.</div> -->
        </div>
    </section>

    <!-- ======= Footer ======= -->
    <footer id="footer">



        <div class="footer-newsletter">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-6">
                        <h4>Subscribe To Our Newsletter</h4>
                        <p>Subscribe to get the best and latest updates from Ghana Library Authority.</p>
                        <form [formGroup]="newsletterSubscribe" (ngSubmit)="newsletterSubscription(newsletterSubscribe.value)" class='formSubscription'>
                            <input placeholder="Type your Email Address" type="email" class='subscribeInput' pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" title="eg. example@example.com" formControlName="email" required><button type="submit" class='subscribeClick'
                                [disabled]="!newsletterSubscribe.valid"><span class='subscribe' >Subscribe</span></button>
                        </form>
                        <div class="alert " style='margin-top:10px; display:none;'>

                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div class="footer-top">
            <div class="container" style='padding:20px;'>
                <div class="row">

                    <div class="col-lg-3 col-md-6 footer-contact">
                        <h3>GhLA</h3>
                        <p>Ghana Library Authority</p>
                        <p> P O Box GP 663</p>
                        <p>Thorpe Rd, Accra, Ghana </p>
                        <p>Ghana Post GPS Address GA-183-1723</p>
                        <br>
                        <div class="row">
                            <div class="col-md-2">
                                <strong>Phone:</strong>
                            </div>
                            <div class="col-md-10">
                                <a href="tel:0302915511"> +233 (0)302915511</a> / <br> <a href="tel:0596658353">+233 (0)596658353</a>
                            </div>
                        </div> <br>
                        <p><strong>Email:</strong> <a href="mailto:support@library.gov.g"> support@library.gov.gh</a><br>
                        </p>
                    </div>
                    <table style="margin: 0% auto; width:400px; ">
                        <tr>
                            <td>
                                <div class="col-lg-12 col-md-12 footer-links" style='text-align:left;'>
                                    <h4>Useful Links</h4>
                                    <ul>
                                        <li><i class="bx bx-chevron-right"></i><a routerLink="/Newsevents">Library News</a></li>
                                        <li><i class="bx bx-chevron-right"></i><a routerLink="/About">About</a></li>
                                        <li><i class="bx bx-chevron-right"></i><a href="https://ils.ghanalibrary.org" target="_blank">Catalog</a></li>
                                        <li><i class="bx bx-chevron-right"></i><a href='https://isbn.library.gov.gh'>ISNS Purchase</a></li>
                                        <li><i class="bx bx-chevron-right"></i><a routerLink="/LocationFinder">Location Finder</a></li>
                                    </ul>
                                </div>
                            </td>

                            <td style="padding-top: 0px;">

                                <div class="col-lg-12 col-md-12 footer-links" style='margin-top: -75px; text-align:left;'>
                                    <h4>Timing</h4>
                                    <ul>

                                        <li><i class="bx bx-chevron-right"></i>
                                            <a> <time datetime="2017-02-13">Mon - Fri: 9 am - 5 pm</time></a>
                                        </li>
                                        <li><i class="bx bx-chevron-right"></i> <a><time datetime="2017-02-13">Sat: 9 am - 1 pm</time></a></li>
                                        <li><i class="bx bx-chevron-right"></i>
                                            <a> <time datetime="2017-02-13">Sun: Closed</time></a>
                                        </li>
                                        <!-- <li><i class="bx bx-chevron-right"></i> <a routerLink="/LocationFinder" style='color:white;'> Branches</a></li> -->

                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </table>





                    <div class="col-lg-3 col-md-6 footer-links">
                        <h4>Follow Us</h4>
                        <p>Follow us on our social media pages.</p>
                        <div class="social-links mt-3">
                            <a href="https://twitter.com/ghanalibraries" target="_blank" class="twitter"><i class="bx bxl-twitter"></i></a>
                            <a href="https://web.facebook.com/ghanalibraries?_rdc=1&_rdr" target="_blank" class="facebook"><i class="bx bxl-facebook"></i></a>
                            <a href="https://www.instagram.com/ghanalibraries/?hl=en" target="_blank" class="instagram"><i class="bx bxl-instagram"></i></a>
                            <a href="https://www.youtube.com/channel/UCXUjas3Yw8i1H-3sdok-puw" target="_blank" class="google-plus"><i class="bx bxl-youtube"></i></a>
                            <a href="https://www.linkedin.com/organization-guest/company/ghanalibraries?trk=similar-pages_result-card_full-click&challengeId=AQFpoXtjfRG85AAAAXNMwODJCKbi_-H_3526KmdvhrWKRzz_d_za53TI1drUGyCBjrH7Bnl4otv7EGtJiOEXYKLaGGJPbXbEDA&submissionId=cb135208-ea95-2116-5928-cc23ba0de745"
                                target="_blank" class="linkedin"><i class="bx bxl-linkedin"></i></a>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="container footer-bottom clearfix">
            <div class="copyright" style='text-align:center;'>
                &copy; 2018 - <span [innerHTML]="date | date: 'yyyy'"></span> <span>. GHANA LIBRARY AUTHORITY</span>. All Rights Reserved!
            </div>

        </div>
    </footer>
    <!-- End Footer -->

    <a href="#" class="back-to-top"><i class="ri-arrow-up-line"></i></a>
    <div id="preloader"></div>
</div>