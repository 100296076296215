
   <div class="p-4 p-md-5 pt-5">
         <!---Popular E-books-->
        <div style='margin-bottom:40px;'>
          <div class="row">
            <div class="col-md-6" style='text-align:left'>
              <p>Popular E-books</p>
            </div>
   
            <div class="col-md-6" style='text-align:right; font-weight: 600; font-size:16px;'>
             <p >See More</p>
           </div>
          </div>
         
   
          <div class="row">
            <div class="col-md-2" >
               <div class='item-display-cards'>
   
               </div>
            </div>
   
            <div class="col-md-2">
             <div class='item-display-cards'>
   
             </div>
   
           </div>
   
   
           <div class="col-md-2">
             <div class='item-display-cards'>
   
             </div>
   
           </div>
   
           <div class="col-md-2">
             <div class='item-display-cards'>
   
             </div>
   
           </div>
   
           <div class="col-md-2">
             <div class='item-display-cards'>
   
             </div>
   
           </div>
   
           <div class="col-md-2">
             <div class='item-display-cards'>
   
             </div>
   
           </div>
          </div>
        </div>
  
  
  
         <!---Popular Videos-->
         <div style='margin-bottom:40px;'>
          <div class="row">
            <div class="col-md-6" style='text-align:left'>
              <p>Popular Videos</p>
            </div>
   
            <div class="col-md-6" style='text-align:right; font-weight: 600; font-size:16px;'>
             <p>See More</p>
           </div>
          </div>
         
   
          <div class="row">
            <div class="col-md-2" >
               <div class='item-display-cards'>
   
               </div>
            </div>
   
            <div class="col-md-2">
             <div class='item-display-cards'>
   
             </div>
   
           </div>
   
   
           <div class="col-md-2">
             <div class='item-display-cards'>
   
             </div>
   
           </div>
   
           <div class="col-md-2">
             <div class='item-display-cards'>
   
             </div>
   
           </div>
   
           <div class="col-md-2">
             <div class='item-display-cards'>
   
             </div>
   
           </div>
   
           <div class="col-md-2">
             <div class='item-display-cards'>
   
             </div>
   
           </div>
          </div>
        </div>
  
  
        <!---Popular Audio books-->
        <div style='margin-bottom:40px;'>
          <div class="row">
            <div class="col-md-6" style='text-align:left'>
              <p>Popular Audio books</p>
            </div>
   
            <div class="col-md-6" style='text-align:right; font-weight: 600; font-size:16px;'>
             <p>See More</p>
           </div>
          </div>
         
   
          <div class="row">
            <div class="col-md-2" >
               <div class='item-display-cards'>
   
               </div>
            </div>
   
            <div class="col-md-2">
             <div class='item-display-cards'>
   
             </div>
   
           </div>
   
   
           <div class="col-md-2">
             <div class='item-display-cards'>
   
             </div>
   
           </div>
   
           <div class="col-md-2">
             <div class='item-display-cards'>
   
             </div>
   
           </div>
   
           <div class="col-md-2">
             <div class='item-display-cards'>
   
             </div>
   
           </div>
   
           <div class="col-md-2">
             <div class='item-display-cards'>
   
             </div>
   
           </div>
          </div>
        </div>
  </div>
      
  