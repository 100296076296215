
  <!-- <div style='margin-bottom:40px;'>


    <div class="row">
      <div class="col-md-12" style='text-align:center;'>
        <h1 style='font-weight:600;  color:#033600;'>CHANGE PASSWORD</h1>
        <p>To change your password, please fill below inputs.</p>

      </div>

      <app-alert-dialog [title]="'Alert!'" [message]="message"> </app-alert-dialog>
      <app-loader></app-loader>

      <form style="width: 100vh;" [formGroup]="updatePasswordForm">

         <div class="row">

        
        <div class="col-md-12 input-container" style='text-align:center; border:1px solid black;'>
          <input formControlName="current" type='password' placeholder="Old Password">
        </div>

        <div class="col-md-12 input-container" style='text-align:center;'>
          <input formControlName="password1" type='password' placeholder="New Password">
        </div>

        <div class="col-md-12 input-container" style='text-align:center;'>
          <input formControlName="password2" type='password' placeholder="Confirm Password">
        </div>

        <div class="col-md-12 input-container" style='text-align:center;'>
        <button [disabled]="!updatePasswordForm.valid" (click)="updatePassword()" class="btn btn-primary"
          style='font-weight:600;  background: #033600; font-size:28px; border:none;'>SUBMIT</button>
        </div>
      </div>
      </form>

      <!-- <div class="col-md-12 input-container" style='text-align:center;'>
        <button [disabled]="!updatePassword.valid" class="btn btn-primary" value="Change Password"
          style='font-weight:600; background: #033600; font-size:28px; border:none;'></button>
      </div> 
      
        
     
   </div>



   </div> -->








   <div class='changeP' style='margin-bottom:40px;'>
           
          
    <div class="row">
      <div class="col-md-12" style='text-align:center;'>
        <h1 style='font-weight:600;  color:#033600;'>CHANGE PASSWORD</h1>
        <p>To change your password, please fill below inputs.</p>

      </div>

      <app-alert-dialog [title]="'Alert!'" [message]="message"> </app-alert-dialog>
      <app-loader></app-loader>

 
      <form style="width: 100%;" [formGroup]="updatePasswordForm">
       
        <div class="col-md-12 input-container" style='text-align:center'>
          <input formControlName="current" type='password' placeholder="Old Password">
        </div>

        <div class="col-md-12 input-container" style='text-align:center;'>
          <input formControlName="password1" type='password' placeholder="New Password">
        </div>

        <div class="col-md-12 input-container" style='text-align:center;'>
          <input formControlName="password2" type='password' placeholder="Confirm Password">
        </div>


      <div class="col-md-12 input-container" style='text-align:center;'>
        <input type='button'  [disabled]="!updatePasswordForm.valid" (click)="updatePassword()" class="btn btn-primary" value="Change Password" style='font-weight:600; background: #033600; font-size:20px; border:none;'>
      </div>
   

  </form>
   
  


  </div>

  </div>