<app-alert-dialog [title]="'Error!'" [message]="errorMessage">
</app-alert-dialog>
<app-loader></app-loader>

<section id="first" class="story" data-speed="8" data-type="background">
    <div class="smashinglogo" id="welcomeimg" data-type="sprite" data-offsetY="100" data-Xposition="50%" data-speed="-2">
        <div class="row details">
            <div class="col-md-8 details-left mob-hide">
                <div class="row">
                    <div class="col-md-1">
                        <img src="../../assets/logo/ghlaLogo.png" style="width: 80px;" />
                    </div>

                    <div class="col-md-11">
                        <h1 style="
                font-weight: 600;
                color: #033600;
                margin-top: 30px;
                margin-left: 20px;
              ">
                            GHANA LIBRARY AUTHORITY
                        </h1>
                    </div>
                </div>

                <div class="col-md-9" style="margin-bottom: 160px; margin-top: 70px;">
                    <h1 style="font-size: 60px; font-weight: 600; color: #033600;">
                        WELCOME <span style="color: #ffb200;">BACK!</span>
                    </h1>
                    <p style="
              font-size: 20px;
              line-height: 40px;
              color: #033600;
              font-weight: 600;
            ">
                        The Ghana Library Authority, offers you a collection of it's digital library contents. Read, Watch and listen to our eBook, video and audiobook collections even as you are empowered to seek knowledge for yourself.
                    </p>

                    <div class="submit-btn" style="width: 100%; margin-top: 30px;">
                        <button routerLink="/" class="btn btn-primary" style="
                font-weight: 600;
                background: #033600;
                border-color: #033600;
              ">
              <i class="fa fa-arrow-left fa-spin" aria-hidden="true"></i> Home
            </button>
                    </div>
                </div>
            </div>

            <div class="col-md-4 details-right">
                <div class="container">
                    <div style="width: 100%; text-align: center;">
                        <img src="../../../../../assets/logo/LOGO-TRANSPARENT.png" width="180" />
                    </div>

                    <form [formGroup]="loginForm" class="form-md">
                        <div class="form-group">
                            <table style="width: 100%;">
                                <tr>
                                    <td>
                                        <span class="icon fa fa-user fa-lg" style="margin-top: -15px;"></span>
                                    </td>
                                    <td>
                                        <input formControlName="username" id="name" class="form-control" type="text" required />
                                        <label class="form-control-placeholder" for="name">Library ID | Email | Phone</label
                    >
                  </td>
                </tr>
              </table>
            </div>
            <div class="form-group" style="margin-top: -20px;">
              <table style="width: 100%;">
                <tr>
                  <td>
                    <span
                      class="icon fa fa-key fa-lg"
                      style="margin-top: -15px;"
                    ></span>
                  </td>
                  <td>
                    <input
                      formControlName="password"
                      id="password"
                      class="form-control"
                      type="password"
                      required
                    />
                    <label class="form-control-placeholder" for="password"
                      >Password</label
                    >
                  </td>
                  <td>
                    <span id="eye" class="iconn fa fa-eye-slash fa-lg"></span>
                  </td>
                </tr>
              </table>
            </div>

            <table class="remember">
              <tr>
                <td style="width: 25px;">
                  <label
                    class="container-checkbox form-check-label"
                    style="margin-top: -10px;"
                  >
                    <input
                      type="checkbox"
                      class="form-check-input"
                      checked="checked"
                    />
                    <span class="checkmark"></span>
                  </label>
                                    </td>
                                    <td>Remember me</td>

                                    <td style="text-align: right;">
                                        <a routerLink="/Forgotpassword" style="color: black; cursor: pointer;"><label
                      class="form-check-label"
                      for="exampleCheck1"
                      style="cursor: pointer;"
                      >Forgot Password?</label
                    ></a
                  >
                </td>
              </tr>
            </table>

            <div class="submit-btn">
              <button
                [disabled]="!loginForm.valid"
                (click)="login()"
                class="btn btn-primary"
                style="font-weight: 600;"
              >
                SIGN IN
              </button>
            </div>

            <div style="text-align: center; margin: 0% auto;">
              <p
                style="
                  font-size: 15px;
                  font-weight: 600;
                  color: #033600;
                  margin-top: -20px;
                "
              >
                Connect Social
              </p>
              <table class="row" style="margin: 0% auto; width: 150px;">
                <td style="width: 33px;">
                  <a
                    href="https://web.facebook.com/ghanalibraries?_rdc=1&_rdr"
                    target="_blank"
                    ><i class="icofont-facebook" id="facebook"></i
                  ></a>
                                    </td>

                                    <td style="width: 33px;">
                                        <a href="https://www.instagram.com/ghanalibraries/?hl=en" target="_blank"><i
                      class="icofont-instagram"
                      id="insta"
                      aria-hidden="true"
                    ></i
                  ></a>
                                    </td>

                                    <td style="width: 33px;">
                                        <a href="https://twitter.com/ghanalibraries" target="_blank"><i class="icofont-twitter" id="twitter"></i
                  ></a>
                                    </td>

                                    <td style="width: 33px;">
                                        <a href="https://www.linkedin.com/organization-guest/company/ghanalibraries?trk=similar-pages_result-card_full-click&challengeId=AQFpoXtjfRG85AAAAXNMwODJCKbi_-H_3526KmdvhrWKRzz_d_za53TI1drUGyCBjrH7Bnl4otv7EGtJiOEXYKLaGGJPbXbEDA&submissionId=cb135208-ea95-2116-5928-cc23ba0de745"
                                            target="_blank"><i class="icofont-linkedin" id="link"></i
                  ></a>
                                    </td>
                            </table>
                        </div>

                        <div style="width: 100%; margin: 2% auto; text-align: center;">
                            <a>
                                <label class="form-check-label" for="exampleCheck1" style="
                    font-size: 15px;
                    font-weight: 600;
                    color: rgb(145, 145, 145);
                  ">New to the library?</label
                >
                <label
                  (click)="navigateTo('signup')"
                  class="form-check-label"
                  for="exampleCheck1"
                  style="
                    margin-left: 10px;
                    font-size: 18px;
                    color: #033600;
                    font-weight: 700;
                    cursor: pointer;
                  "
                  >Sign Up</label
                ></a
              >

              <div
                class="submit-btn home-mob"
                style="width: 40%; margin-top: 30px;"
              >
                <button
                  routerLink="/"
                  class="btn btn-primary"
                  style="
                    font-weight: 600;
                    background: #033600;
                    border-color: #033600;
                  "
                >
                  <i class="fa fa-arrow-left fa-spin" aria-hidden="true"></i>
                  Home
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="col-md-12 mob-hide">
        <div class="footer">
          <div class="row">
            <div class="col-md-3">
              <a
                href="/docs/privacy.html"
                target="_blank"
              >
                <h1 style="font-size: 25px; font-weight: 600; color: #033600;">
                  Privacy Policy
                </h1></a
              >
            </div>

            <div class="col-md-5" style="text-align: left;">
              <a
                href="/docs/terms.html"
                target="_blank"
                ><h1 style="font-size: 25px; font-weight: 600; color: #033600;">
                  Terms & Conditions
                </h1></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>