<!-- <div (click)="viewDetails()" class='list' style="margin: 5px;color: black;width: 230px;height: 350px; background-color: white;padding: 1px;border-radius: 2px;border-width: 0.11px;   cursor: pointer; margin-bottom:120px;" >

    <img stretch="aspectFill"  src="{{parsedBook.cover}}"
        style="height:300px;width:95%;background-color: gray;margin: 3px; border:1px solid rgb(189, 189, 189);
        box-shadow: 1px 2px 3px rgb(173, 173, 173);">

    <div class='card-body'style="vertical-align: middle; width:95%; border:1px solid rgb(189, 189, 189);
    box-shadow: 1px 2px 3px rgb(173, 173, 173); margin-left:2.7px;">
        <span textWrap="true" style="font-size: 14px;font-weight: bold;">{{parsedBook.title}}</span>
        <p style="font-size: 10;margin-top: 3px;" >{{parsedBook.author}}</p>
        <p style="font-size: 7;margin-top: 3px;font-weight: bold;">{{mediaType}}</p>
    </div>
   
</div> -->

<ul class="list-group" style='padding-top:20px;'>
    <li class="media" (click)="viewDetails()">
    <div style='max-height:40px'>
      <img class="mr-3" src="{{parsedBook.cover}}" style='width:50px; height:60px;' alt="Generic placeholder image">
    </div>
      <div class="media-body" style='border-bottom:1px solid rgb(224, 224, 224);'>
        <h3 class="mt-0 mb-1">{{parsedBook.title}}</h3>
        <h5 class="mt-0 mb-1">{{parsedBook.author}}</h5>
        {{mediaType}}
      </div>
    </li>
    </ul>


   
  

