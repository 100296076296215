import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { AuthenticationService } from "src/app/services/auth.service";
import { map } from "rxjs/operators";
declare var $;

@Component({
  selector: "web-home",
  templateUrl: "./web-home.component.html",
  styleUrls: ["./web-home.component.css", "../css/styles.css"],
})
export class WebHomeComponent implements OnInit {
  response: any;
  email: any;
  newsletterSubscribe: FormGroup;
  date = new Date();
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    private fb: FormBuilder,
    private auth: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.newsletterSubscribe = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
    });

    // setInterval(function(){
    // $('.one').fadeOut('slow');
    // $('.two').fadeOut('slow');
    // $('.three').fadeOut('slow');
    // $('.four').fadeOut('slow');
    // setTimeout(function(){
    // $('.two').fadeIn('slow');
    // $('.four').fadeIn('slow');
    // },800);
    // },8000);

    // setInterval(function(){
    //   $('.two').fadeOut('slow');
    //   $('.one').fadeOut('slow');
    //   $('.three').fadeOut('slow');
    //   $('.four').fadeOut('slow');
    //   setTimeout(function(){
    //   $('.three').fadeIn('slow');
    //   $('.four').fadeIn('slow');
    //   },800);
    //   },14000);

    //   setInterval(function(){
    //     $('.three').fadeOut('slow');
    //     $('.two').fadeOut('slow');
    //     $('.one').fadeOut('slow');
    //     $('.four').fadeOut('slow');
    //     setTimeout(function(){
    //     $('.one').fadeIn('slow');
    //     $('.four').fadeIn('slow');
    //     },800);
    //     },22000);

    if ($(".nav-menu").length) {
      $(document).on("click", ".mobile-nav-toggle", function (e) {
        $(".body").toggleClass("mobile-nav-active");
        $(".mobile-nav-toggle i").toggleClass(
          "icofont-navigation-menu icofont-close"
        );
        $(".mobile-nav-overly").show();
        $(".mobile-nav").show();
        $(".mobile-nav-toggle1").show();
      });

      $(document).on("click", ".mobile-nav-toggle1", function (e) {
        $(".mobile-nav, .mobile-nav-toggle1").hide();
        $(".mobile-nav-toggle i")
          .removeClass("icofont-close")
          .addClass("icofont-navigation-menu");
        $(".mobile-nav-overly").fadeOut();
      });

      $(document).on("click", ".mobile-nav .drop-down > a", function (e) {
        e.preventDefault();
        $(this).next().slideToggle(300);
        $(this).parent().toggleClass("active");
      });
    }

    $(".back-to-top").click(function () {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        1500,
        "easeInOutExpo"
      );
      return false;
    });
  }

  sendSubscribeEmail(data) {
    $(".subscribe").html("<i class='fa fa-spinner fa-spin'></i>");

    return this.http.post("/en/ghlaapi/subscription.php", data).pipe(
      map((response) => {
        return response;
      })
    );
  }

  // /en/ghlaapi/subscription.php

  newsletterSubscription(value) {
    const subscription = new FormData();
    subscription.append("email", value.email);
    this.sendSubscribeEmail(subscription).subscribe((response) => {
      // this.router.navigate(['']);
      this.response = response;
      console.log(this.response);
      var result = this.response;

      if (result == 1) {
        $(".alert").removeClass("alert-danger");
        $(".alert").addClass("alert-success");
        $(".alert").html(
          "<strong>Success!</strong> you have been subscribed to receive latest updates."
        );
        $(".alert-success").show();
        setTimeout(function () {
          $(".alert-success").fadeOut();
          $(".subscribe").html("Subscribe");
          $(".formSubscription")[0].reset();
        }, 2000);
      } else if (result == 2) {
        $(".alert").removeClass("alert-success");
        $(".alert").addClass("alert-danger");
        $(".alert").html(
          "<strong>Could not be processed!</strong> You have already subscribed to our newsletter."
        );
        $(".alert-danger").show();
        setTimeout(function () {
          $(".alert-danger").fadeOut();
          $(".subscribe").html("Subscribe");
          $(".formSubscription")[0].reset();
        }, 2000);
      }
    });
  }

  // newsletterSubscription(){
  //   var test = { email: "torsuichard@gmail.com" }
  //   this.http.post<any>('https://localhost/GHLA-WEB-PROPER/src/app/landing-website/components/ghlaapi/subscription.php', test).subscribe(response => {
  //     this.response = response;
  //     // console.log(this.articleread);
  //     if(this.response.status == 201){
  //       console.log("Subscription successful");
  //     }else{
  //       console.log("Error")
  //     }
  //   },error => console.error(error));
  // }
}
