import { NgModule } from '@angular/core';
import { Routes, RouterModule, Route } from '@angular/router';
import { AudiobookDetailComponent } from './digi-lib/components/audiobook-detail/audiobook-detail.component';
import { ChangePasswordComponent } from './digi-lib/components/change-password/change-password.component';
import { ContentViewComponent } from './digi-lib/components/content-view/content-view.component';
import { CourseSelectionComponent } from './digi-lib/components/course-selection/course-selection.component';
import { DigiLibraryComponent } from './digi-lib/components/digi-library/digi-library.component';
import { EpubReaderComponent } from './digi-lib/components/digi-library/epup-reader/epub-reader.component';
import { VideoPlayerComponent } from './digi-lib/components/digi-library/video-player/video-player.component';
import { EbookDetailComponent } from './digi-lib/components/ebook-detail/ebook-detail.component';
import { ReaderComponent } from './digi-lib/components/ebook-detail/reader/reader.component';
import { BranchesComponent } from './digi-lib/components/find-my-library/branches/branches.component';
import { FindMyLibraryComponent } from './digi-lib/components/find-my-library/find-my-library.component';
import { MapViewerComponent } from './digi-lib/components/find-my-library/map-viewer/map-viewer.component';
import { HelpAndFeedbackComponent } from './digi-lib/components/help-and-feedback/help-and-feedback.component';
import { HomeComponent } from './digi-lib/components/home.component';
import { MyShelfComponent } from './digi-lib/components/my-shelf/my-shelf.component';
import { SearchViewComponent } from './digi-lib/components/search-view/search-view.component';
import { SeeAllComponent } from './digi-lib/components/see-all/see-all.component';
import { SettingsComponent } from './digi-lib/components/settings/settings.component';
import { SigninHomeComponent } from './digi-lib/components/signin-home/signin-home.component';
import { ForgotPasswordComponent } from './digi-lib/components/signin/forgot-password/forgot-password.component';
import { ReplacePasswordComponent } from './digi-lib/components/signin/replace-password/replace-password.component';
import { SigninComponent } from './digi-lib/components/signin/signin.component';
import { VerificationComponent } from './digi-lib/components/signin/verification/verification.component';
import { SignupComponent } from './digi-lib/components/signup/signup.component';
import { SuggestedComponent } from './digi-lib/components/suggested/suggested.component';
import { VideoDetailComponent } from './digi-lib/components/video-detail/video-detail.component';
import { AuthGuard } from './guards/auth.guard';
import { ReplacePasswordGuard } from './guards/replace-password.guard';
import { SigninGuard } from './guards/signin.guard';
import { AboutComponent } from './landing-website/components/about/about.component';
import { BookingFacilitiesComponent } from './landing-website/components/booking-facilities/booking-facilities.component';
import { Erro404Component } from './landing-website/components/erro404/erro404.component';
import { FirstPageComponent } from './landing-website/components/home/home.component';
import { LocationfinderComponent } from './landing-website/components/location-finder/location-finder.component';
import { WebBranchesComponent } from './landing-website/components/location-finder/web-branches/web-branches.component';
import { WebMapViewerComponent } from './landing-website/components/location-finder/web-map-viewer/web-map-viewer.component';
import { NewsEventsComponent } from './landing-website/components/news-events/news-events.component';
import { ReadNewsComponent } from './landing-website/components/news-events/read-news/read-news.component';
import { RedirectComponent } from './landing-website/components/redirect/redirect.component';
import { ServicesComponent } from './landing-website/components/services/services.component';
import { WebHomeComponent } from './landing-website/components/web-home.component';


const routes: Route[] = [
  {
    path: "",
    component: WebHomeComponent,
    children: [
      { path: "", component: FirstPageComponent },
      { path: "About", component: AboutComponent },
      { path: "LocationFinder", component: LocationfinderComponent },
      { path: "Webbranches", component: WebBranchesComponent },
      { path: "Webmapviewer", component: WebMapViewerComponent },
      { path: "Newsevents", component: NewsEventsComponent },
      { path: "Readnews", component: ReadNewsComponent },
      { path: "Services", component: ServicesComponent },
      { path: "Downloadourapp", component: RedirectComponent },
      { path: "BookingFacilities", component: BookingFacilitiesComponent },
    ],
  },
  { path: "signin", component: SigninComponent, canActivate: [SigninGuard] },
  { path: "Forgotpassword", component: ForgotPasswordComponent },
  { path: "Verification", component: VerificationComponent },
  {
    path: "Replacepassword",
    component: ReplacePasswordComponent,
    canActivate: [ReplacePasswordGuard],
  },
  { path: "signup", component: SignupComponent },
  {
    path: "home",
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", redirectTo: "discover", pathMatch: "full" },
      { path: "discover", component: SigninHomeComponent },
      { path: "Myshelf", component: MyShelfComponent },
      { path: "Changepassword", component: ChangePasswordComponent },
      { path: "Findmylibrary", component: FindMyLibraryComponent },
      { path: "Help&feedback", component: HelpAndFeedbackComponent },
      { path: "Suggested", component: SuggestedComponent },
      { path: "Settings", component: SettingsComponent },
      { path: "Courseselection", component: CourseSelectionComponent },
      { path: "DigiLibrary", component: DigiLibraryComponent },
      { path: "content", component: ContentViewComponent },
      { path: "Branches", component: BranchesComponent },
      { path: "Mapviewer", component: MapViewerComponent },
      { path: "Video", component: VideoPlayerComponent },
      { path: "Epub", component: EpubReaderComponent },
      { path: "ebook-details", component: EbookDetailComponent },
      { path: "audiobook-details", component: AudiobookDetailComponent },
      { path: "video-details", component: VideoDetailComponent },
      { path: "reader", component: ReaderComponent },
      { path: "more", component: SeeAllComponent },
      { path: "search", component: SearchViewComponent },
    ],
  },
  { path: "**", component: Erro404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
