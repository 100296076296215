<div class="p-1 p-md-0 pt-5" style='margin-top:-55px;'>
    <div class="col-md-12 banner">
        <div class="row">
            <div class="col-md-12">
               <h1 style="color:white; font-size:100px;">12345  &nbsp;&nbsp;12345 &nbsp;&nbsp;20120</h1>
            </div>
   
            <div class="col-md-8" style="text-align:left;  margin:0% auto;">
               <p style="color:white; font-size:50px; font-family:monospace; font-weight: 0;">Eugene Markins</p>
            </div>
        </div>
       
    </div>
   
    <div class="row">
   
    
   <div class="col-md-3">
       <div class="row">
           <div class="small-12 medium-2 large-2 columns">
             <div class="circle">
               <!-- User Profile Image -->
               <img class="profile-pic" src="../../../../assets/TOON.jpg">
        
               <!-- Default Image -->
               <!-- <i class="fa fa-user fa-5x"></i> -->
             </div>
             <div class="p-image">
               <i class="fa fa-camera upload-button"></i>
                <input class="file-upload" type="file" accept="image/*"/>
             </div>
          </div>
        </div>
   </div>
   
   <div class="col-md-9" style='padding-top: 90px;'>
   <div class="col-md-12 input-containner">
     <label>Name</label> <input type='text' >
   </div>
   
   <div class="col-md-12 input-containner">
       <label>City</label> <input type='text' >
     </div>
   
   
     <div class="col-md-12 input-containner">
       <label>Date of birth</label> <input type='date' >
     </div>
   
   
     <div class="col-md-12 input-containner">
       <label>Gender</label> <input type='text' >
     </div>
   
     <div class="col-md-12 input-containner">
       <label>Phone Number</label> <input type='text'>
     </div>
   
     <div class="col-md-12 input-containner">
       <label>Profession</label> <input type='text' >
     </div>
   
     <div class="col-md-12 input-containner">
       <label>Email Address</label> <input type='text' >
     </div>
   </div>
   </div>
   </div>