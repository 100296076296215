
<app-alert-dialog [title]="'Alert!'" [message]="message" > </app-alert-dialog>
<app-loader></app-loader>
<section id="first" class="story" data-speed="8" data-type="background">
    <div class="smashinglogo" id="welcomeimg" data-type="sprite" data-offsetY="100" data-Xposition="50%" data-speed="-2">
       <div class="row details">
        
           
       
        <div class="col-md-8 details-left mob-hide">
          
          <img src="../../../../../assets/digi-library/icons/avatar.png" width="480" class="rounded-circle">
          
 
        
 
       </div>
        
 
        
          <div class="col-md-4 details-right">
             <div class='container'>
                <div style='width:100%; text-align:left;'>
                    <h1 style='font-weight:600;  color:#033600;'>Change Password</h1>
                    <p style="font-size:17px; color:rgb(87, 86, 86)">Enter your new password.</p>
                 </div>
                <form [formGroup]="resetPasswordForm" class="form-md">
 
                  
                  
                   <div class="form-group">
                      <table style='width:100%;'>
                         <tr>
                            <td></td>
                            <td>
                               <input formControlName="password1" id="name" class="form-control" type="password" required>
                               <label class="form-control-placeholder" for="name">Enter Password</label>
                            </td>
                           
                         </tr>
                      </table>
                      
                     
                     
                    </div>

                    <div class="form-group" style="margin-top:-25px;">
                        <table style='width:100%;'>
                           <tr>
                              <td></td>
                              <td>
                                 <input formControlName="password2" id="name" class="form-control" type="email" required>
                                 <label class="form-control-placeholder" for="name">Confirm Password</label>
                              </td>
                             
                           </tr>
                        </table>
                        
                       
                       
                      </div>
                    
               
 
                   <div class='submit-btn'>
                    <button [disabled]="!resetPasswordForm.valid" (click)="resetPassword()" class="btn btn-primary" style='font-weight:600;'>SUBMIT</button>
                   </div>

                   <div class='submit-btn' style='margin-top:-25px;'>
                     <button routerLink="/signin" class="btn btn-primary" style='font-weight:600; background: rgb(172, 129, 64); '>Go to signin</button>
                  </div>
 
                    
 
                </form>
    
    
    
             </div>
             </div>
 
           
 
        </div>
        </div>
   
 </section>