  <!-- Loader / Throbber -->
  <div class="modal fade" id="loader" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    data-backdrop="static" data-keyboard="false" aria-hidden="true" style='margin:30vh auto;'>
    <div class="modal-dialog" role="document">
      <div class="modal-content">

        <div class="modal-body" style='padding:100px; text-align:center;'>
          <div class="spinner-grow text-muted"></div>
          <div class="spinner-grow text-primary"></div>
          <div class="spinner-grow text-success"></div>
          <div class="spinner-grow text-info"></div>
          <div class="spinner-grow text-warning"></div>
          <div class="spinner-grow text-danger"></div>
          <div class="spinner-grow text-secondary"></div>
          <div class="spinner-grow text-dark"></div>
          <div class="spinner-grow text-light"></div>
          <p style='margin-top:30px;'>Loading please wait........</p>
        </div>

      </div>
    </div>
  </div>

  <!-- <div class="overlay"></div> -->