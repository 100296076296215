<div style='margin-top:-22px;'>
    <div class="row"  style='border:0px solid black;'>
        <div class="col-md-12" [ngStyle]="{'background-image':'url('+ebook.cover+')'}" style='background-size: cover; background-repeat: no-repeat;  white-space: nowrap; background-position: center;'>
            <div class="row" style="background:rgba(0, 0, 0, 0.486);">
                <div [ngStyle]="{'background-image':'url('+ebook.cover+')'}"
                    style='height:30vh;background: rgb(161, 161, 161); margin:0% auto; border:5px solid white; background-size: cover; background-repeat: no-repeat;  white-space: nowrap; background-position: center; width:200px;'>

                </div>
            </div>


        </div>

        <div class="col-md-12" style='text-align:center;'>
            <h3 style='font-weight:700;'>{{ebook.title}}</h3>
           
            <p style='height:10px; color:rgb(126, 126, 126);'><u style='font-weight:600;'>Author(s):</u> {{ebook.author}}</p><br>
            <p style='height:10px; color:rgb(126, 126, 126);'><span><u style='font-weight:600;'>Publisher:</u> </span> <span>{{ebook.publisher}}</span> ||
                <span><u style='font-weight:600;'>Format:</u> </span> <span>Epub</span> || <span><u style='font-weight:600;'>Language:</u> </span> <span>{{ebook.language}}</span></p>
            <button (click)="loadEpub()" class="btn btn-primary"
                style='font-weight:600; padding: 3px; width:15%; background:#033600; border-color: #033600; margin-top: 40px;'>Read</button>

        </div>

        <div class="col-md-12" style='padding-left:70px; padding-right:70px; margin:2% auto;'>
    
            <p style='line-height:30px; color:rgb(126, 126, 126);'>{{ebook.summary}}
            </p>
            <div class="col-md-12" style='text-align:right;'>
                <!-- <p><a href="#" style='color:#033600;'>read more</a></p> -->
            </div>
        </div>

    </div>
</div>