<div class="row">
  <div class="col-md-1"></div>
  <div class="col-md-11">
  <div class="row">
    <div class="col-md-12">
      <h1 style='font-weight:600;  color:#033600;'>Help & Feedback</h1>
      <p>To change your password, please fill below inputs.</p>

    </div>

    <app-alert-dialog [title]="'Alert!'" [message]="message"> </app-alert-dialog>
      <app-loader></app-loader>
      
    <form style="width: 100vh;" [formGroup]="feedbackForm">
      <div class="col-md-12" style='margin-bottom:30px;'>
        <input formControlName="name" type='text' value='' style=''>
      </div>

      <div class="col-md-12" style='margin-bottom:30px;'>
        <input formControlName="email" type='text' value='' >
      </div>


      <div class="col-md-12" style='margin-bottom:30px;'>
        <textarea formControlName="message"  value='' placeholder="write your message here......." style=' padding:20px; padding-bottom: 140px; border:1px solid rgb(126, 126, 126); font-size:20px;'></textarea>
      </div>


      <div class="col-md-12 btn-div">
        <input  (click)="submitFeedBack()" [disabled]="!feedbackForm.valid" class="btn btn-primary" value="SUBMIT" style=''>
      </div>
    </form>


  </div>
</div>