

<main id="main">

    

    <!-- ======= Pricing Section ======= -->
    <section id="pricing" class="pricing news">
       <div class="container" data-aos="fade-up" style='height:250px;'>
 
        
 
       </div>
     </section><!-- End Pricing Section -->
 <!-- ======= Breadcrumbs ======= -->
 <section id="breadcrumbs" class="breadcrumbs">
   <div class="container">

     <ol>
       <li><a routerLink='/'>Home</a></li>
       <li>Booking & Facilities</li>
     </ol>
     <h2>Booking & Facilities</h2>

   </div>
 </section><!-- End Breadcrumbs -->

   <!-- ======= Portfolio Details Section ======= -->
   <section id="portfolio-details" class="portfolio-details">
    
    <section class="wrapper">
        <div class="container-fostrap" data-aos="fade-up">
            
      <div class="section-title">
        <h2>Booking & Facilities</h2>
        <p>The Ghana Library Authority.</p>
      </div>
            <div class="content">
                <div class="container">
                    <div class="portfolio-description">
                        <h2 style='width:100%;'>Conference &amp; Workshop Venue</h2>
              
                        
                        <p style='line-height:40px; font-size:18px; color:rgb(107, 106, 106);'>
                            As a Centre for connecting citizens around intellectual discussions, GhLA maintains
                            conference facilities across the country. Self-developed events are hosted here and
                            patronized by the local community. Institutions wishing to rent our space can also book
                            to use our facility. Do send us an email <a href="#" style='color:blue;'>estates@library.gov.gh</a> to request a booking
                        </p>
                      </div>
                </div>
            </div>
        </div>
    </section>

   </section><!-- End Portfolio Details Section -->

 </main><!-- End #main -->