<app-alert-dialog [title]="'Alert!'" [message]="message" > </app-alert-dialog>
<app-loader></app-loader>
<section id="first" class="story" data-speed="8" data-type="background">
   <div class="smashinglogo" id="welcomeimg" data-type="sprite" data-offsetY="100" data-Xposition="50%" data-speed="-2">
      <div class="row details">
        
          
      
       <div class="col-md-8 details-left mob-hide">
         
         <img src="../../../../../assets/digi-library/icons/avatar.png" width="480" class="rounded-circle">
         

       

      </div>
       

       
         <div class="col-md-4 details-right">
            <div class='container'>
               <div style='width:100%; text-align:left;'>
                  <h1 style='font-weight:600;  color:#033600;'>Forgot Password</h1>
                  <p style="font-size:17px; color:rgb(87, 86, 86)">Enter your email and we will send you a link to reset
                     password.</p>
               </div>
   
               <form [formGroup]="usernameForm" class="form-md">

                 
                 
                  <div class="form-group">
                     <table style='width:100%;'>
                        <tr>
                           <td> <span class="icon fa fa-envelope fa-lgg" style='margin-top:-15px;'></span></td>
                           <td>
                              <input formControlName="username" id="name" class="form-control" type="email" required>
                              <label class="form-control-placeholder" for="name">eg. youremail@provider.com</label>
                           </td>
                          
                        </tr>
                     </table>
                     
                    
                    
                   </div>
                   
              

                  <div class='submit-btn'>
                     <button [disabled]="!usernameForm.valid" (click)="requestToken()" class="btn btn-primary" style='font-weight:600;'>SUBMIT</button>
                  </div>

                  <div class='submit-btn' style='margin-top:-25px;'>
                     <button routerLink="/signin" class="btn btn-primary" style='font-weight:600; background: rgb(172, 129, 64); '>Go to signin</button>
                  </div>


                   

               </form>
   
   
   
            </div>
            </div>

          

       </div>
       </div>
  
</section>