import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
declare var $;

@Component({
  selector: 'app-ebook-detail',
  templateUrl: './ebook-detail.component.html',
  styleUrls: ['./ebook-detail.component.css']
})
export class EbookDetailComponent implements OnInit {
  ebook;

  constructor(private activeRoute:ActivatedRoute,private router:Router) { 
    
    this.activeRoute.queryParams.subscribe(params=>{
      this.ebook = JSON.parse(params.ebook)
      
    })

  }

  ngOnInit(): void {
    var viewportWidth = $(window).width();
    if (viewportWidth <= 991) {
      $('.mob-logo').hide();
      $('.breadcrumb-com').hide()
      $(' .breadcrumb-mob').show()
      $('.navbar-expand-lg').css('padding','7px');
       $('.mob-logo').hide();
    }else{
      $('.breadcrumb-mob').hide()
      $('.breadcrumb-com').show()
      $('.navbar-expand-lg').css('padding','8px');
    }
  }

  loadEpub(){
    this.router.navigate(["/home/reader"],{queryParams: {resource:encodeURI(this.ebook.resource)} })
  }

}
