
<div id="wrapper">
<nav class="navbar navbar-expand-lg navbar-light fixed-top bg-light nav1">
  <a class='backLink breadcrumb-mob'><i class="icofont-long-arrow-left" style='color: #033600; font-size:50px;'></i></a>
  <a routerLink="/home" class='mob-logo'><img src='../../assets/logo/ghlaLogo.png' width='50'></a>
  <nav aria-label="breadcrumb" class='breadcrumb-com' id='breadcrumb' >
    <ol class="breadcrumb" style='background: none; padding:0px; margin-top:8px; cursor:ponter;'>
      
      <li class="" style='cursor:pointer;'> <a class='backLink' ><span class="icon fa fa-arrow-left fa-lg" ></span></a>
      </li>
      <!-- <li class="" style='margin-left:30px; margin-right:30px;'><a class='forwardLink'><span
            class="icon fa fa-arrow-right fa-lg" style='color: #033600;'></span></a></li> -->

    </ol>
  </nav>
  <form class="navbar-form mob-search" role="search" id="navBarSearchForm" style="width:100%; ">
    <div class="form-group ">
      <div class="input-group">
        <input #searchBoxMob type="text" class="form-control" placeholder="Search by Keyword/ Title/ Author" >
        <span class="input-group-btn">
          <button (click)="searchMob()" class="btn btn-white" type="button"><i class="fa fa-search"
            style='color:rgb(218, 184, 35);'></i></button>
        </span>
      </div>
    </div>
  </form>

  <button class="navbar-toggler" data-target="#navbarCollapse" data-toggle="collapse" aria-expanded="false" >
    <span class="line"></span> 
    <span class="line"></span> 
    <span class="line" style="margin-bottom: 0;"></span>
</button>
 

  <div class="collapse navbar-collapse" id="navbarCollapse">
    <ul class="navbar-nav mr-auto sidenav" id="navAccordion">
      <li class="nav-item  logo">
        <a routerLink="/home" class="logo"><img src='../../../../../assets/logo/LOGO-TRANSPARENT.png' width='180'></a>
      </li>

     
      <li class="nav-item nav-item-parent">
        <a class="nav-link"  routerLink="/">Home</a>
      </li>
      <li class="nav-item nav-item-parent active">
        <a class="nav-link recent-item-click" routerLink="/home/discover">Recently Added & Popular</a>
      </li>
      <li class="nav-item ">
        <a class="nav-link " id="hasSubItems" data-toggle="collapse" data-target="#collapseSubItems2" aria-controls="collapseSubItems2" aria-expanded="false"
       >Discover
       <i class="fa fa-minus pull-right"></i>
        <i class="fa fa-chevron-down pull-right"></i>
      </a>
        <ul class="nav-second-level collapse collapseSubItems2" id="collapseSubItems2">
          <li  class="nav-item list-unstyled nav-item-parent" *ngFor="let level of superNavigationLevel">
            <a  class="nav-link-text " (click)="showContent(level.link._attributes.href,level.title._text)"  
             >{{level.title._text}}</a>
          </li>

        </ul>
     
      </li>
      <li class="nav-item nav-item-parent">
        <a class="nav-link" routerLink="/home/Suggested">Suggested</a>
      </li>
      <li class="nav-item ">
        <a class="nav-link" href="#" id="hasSubItems" data-toggle="collapse" data-target="#collapseSubItems4" aria-controls="collapseSubItems4" aria-expanded="false"
        href="#">My Account
        <i class="fa fa-minus pull-right"></i>
        <i class="fa fa-chevron-down pull-right"></i>
      </a>

        <ul class="nav-second-level collapse collapseSubItems2" id="collapseSubItems4">
          <li  class="nav-item nav-item-child list-unstyled">
            <a  class="nav-link-text "  routerLink="/home/Findmylibrary" >Find My Library</a>
          </li>
          <li  class="nav-item nav-item-child list-unstyled">
            <a  class="nav-link-text"  routerLink="/home/Changepassword">Change Password</a>
          </li>

          <li  class="nav-item nav-item-child list-unstyled">
            <a  class="nav-link-text" routerLink="/home/Help&feedback" >Help & Feedback</a>
          </li>

          <li  class="nav-item nav-item-child list-unstyled">
            <a  class="nav-link-text"  (click)="logout()">Logout</a>
          </li>

        </ul>
        
      </li>
      
    </ul>
    
    <form class="navbar-form ml-auto mt-2 mt-md-0 com-search" role="search" id="navBarSearchForm" style='height:20px; width:30%;'>
      <div class="form-group mr-sm-4" style='padding:0px; margin-top:-10px;'>
        <div class="input-group" style='padding:0px;'>
          <input #searchBox type="text" class="form-control " placeholder="Search by Keyword/ Title/ Author">
          <span class="input-group-btn">
            <button (click)="search()" class="btn btn-white" type="button"><i class="fa fa-search"
              style='color:rgb(218, 184, 35);'></i></button>
          </span>
        </div>
      </div>
    </form>


    
  </div>

 
  
</nav>

<main class="content-wrapper" >
 


  <div class="container-fluid">
    
    <div class="spanner" >
      <div class="loader" style='display:none;'> </div>
      <p style='margin-top:-45vh;'>Fetching content, please be patient.</p>
   
    </div>
    <router-outlet></router-outlet>
    
  </div>

  
  
</main>
<div class="fab-container overlay">
  <div class="fab fab-icon-holder">
      <i class="fa fa-search"></i>
  </div>
  
</div>

<footer class="footer">
<div class="nav bottom-nav-recent ">
          <a  class="nav__link recent-click nav__link--active">
            <i class="material-icons nav__icon">dashboard</i>
            <span class="nav__text">Recently Added</span>
          </a>
          <a  class="nav__link popular-ebooks-click">
            <i class="icofont-ebook nav__icon"></i>
            <span class="nav__text">Popular E-books</span>
          </a>
          <a class="nav__link popular-audios-click">
            <i class="material-icons nav__icon">equalizer</i>
            <span class="nav__text">Popular Audio Books</span>
          </a>
          <a  class="nav__link popular-videos-click">
            <i class="icofont-video-alt nav__icon"></i>
            <span class="nav__text">Popular Videos</span>
          </a>
        </div> 

        <div class="nav bottom-nav-discover" style='display:none;'>
          <a  class="nav__link popular-ebooks-click nav__link--active">
            
            <i class="icofont-ebook nav__icon"></i>
            <span class="nav__text">E-books</span>
          </a>
          <a class="nav__link popular-audios-click">
            <i class="material-icons nav__icon">equalizer</i>
            <span class="nav__text">Audio Books</span>
          </a>
          <a  class="nav__link popular-videos-click">
            <i class="icofont-video-alt nav__icon"></i>
            <span class="nav__text">Videos</span>
          </a>
        </div> 
    
</footer>
</div>