<div class="p-4 p-md-5 pt-5">
  <div style='margin-bottom:40px;'>
    <div class="row">
      <div class="col-md-12" style='text-align:left'>
        <p style='font-weight:600; font-size:18px; color:#033600;'>{{name}}</p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div id="contact" class="map" style='height:65vh; border:1px solid rgb(209, 209, 209);'>
          <agm-map [zoom]="25"  [latitude]="127.1" [longitude]="141.1"  style="height: 65vh;width: 100%;">
            <agm-marker [latitude]="127.1" [longitude]="141.1"></agm-marker>
          </agm-map>
        </div>
      </div>
    </div>

  </div>

</div>