
      <style>
          .section-title{
              font-size:18px;
          }

          p{
           font-size:16px;       
        }
      </style>
        <section class="page-banner services-banner" >
            <div class="container">
                <div class="banner-header" style='margin-top:60px;'>
                    <h2>Our Services</h2>
                    <span class="underline center"></span>
                    <p class="lead">It is impedient that you get to know the services we offer at GhLA</p>
                </div>
                <div class="breadcrumb">
                    <ul>
                        <li><a href="index.html">Home</a></li>
                        <li>Services</li>
                    </ul>
                </div>
            </div>
        </section>
        <!-- End: Page Banner -->
        <!-- Start: Services Section -->
        <div id="content" class="site-content">
            <div id="primary" class="content-area">
                <main id="main" class="site-main">
                    <div class="services-main">
                        <div class="services-pg">                            
                            <section class="services-offering">
                                <div class="container">
                                    <div class="center-content">
                                        <h2 class="section-title">SERVICES WE OFFER</h2>
                                        <span class="underline center"></span>
                                        <p class="lead">The Ghana Library Authority</p>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="clearfix"></div>
                                    
                                </div>
                            </section>

                            
                     
                           <section style='margin-top:-130px;'>
                            <div class="row" style='width:60%; margin:0% auto;'>
                                <div class="col-md-12">
                                    <h3 class="section-title">Technology Support Services</h3>
                                     <span class="underline left"></span>
                                     <p>Across 30 branches in Ghana, GhLA offer opportunity for both school children and adult
                                        to learn various aspect of the use of technological devices to solve everyday problems.
                                        This program forms the heart of our work to equip Ghanaians with modern skills in
                                        retrieving information, as well, exploit tools of technology to help advance their
                                        wellbeing.
                                        The Authority also offers hands-on mobile ICT services to underserved schools and
                                        communities using the Mobile Library Van.</p>
                                     
                                </div>

                                <div class="col-md-12">
                                    <h3 class="section-title">Corporate Institutional Book Lending</h3>
                                     <span class="underline left"></span>
                                     <p>GhLA understands the need of corporate Ghana to be in tuned with current
                                        publications. Our Corporate Book Lending is targeted at all levels of business entities,
                                        wishing to build a reading and knowledgeable workforce. By signing up for Corporate
                                        Book Lending, your institution can visit our regional branches and make selection of
                                        books that could be helpful for your team.</p>
                                     
                                </div>
          

                            </div>
                            
                        
                            <div class="row" style='width:60%; margin:0% auto;'>
                                <div class="col-md-12">
                                    <h3 class="section-title">Individual Book Lending</h3>
                                    <span class="underline left"></span>
                                    <p>Individuals, both children and adult can apply for membership and have access to our
                                       branches around the country, borrow book of their choice.</p>
                                    
                                </div>

                                <div class="col-md-12">
                                    <h3 class="section-title">Family Book Lending</h3>
                                     <span class="underline left"></span>
                                     <p>You can sign up as Family member and borrow books up to 25 copies and return in 21
                                        days. This service is offered in all sixteen regions of Ghana.</p>
                                     
                                </div>
          

                            </div>
                            
                        
                            <div class="row" style='width:60%; margin:0% auto;'>
                                <div class="col-md-12">
                                     <h3 class="section-title">School Book Lending</h3>
                                     <span class="underline left"></span>
                                     <p>Your school lack library services? Or limited stock? Join our School Book Lending
                                        program and borrow a box of books to be used by your school for the benefit of
                                        Ghanaian children.</p>
                                     
                                </div>

                                <div class="col-md-12">
                                    <h3 class="section-title">Corporate Institutional Book Lending</h3>
                                    <span class="underline left"></span>
                                    <p>AGhLA understands the need of corporate Ghana to be in tuned with current
                                        publications. Our Corporate Book Lending is targeted at all levels of business entities,
                                        wishing to build a reading and knowledgeable workforce. By signing up for Corporate
                                        Book Lending, your institution can visit our regional branches and make selection of
                                        books that could be helpful for your team.</p>
                                    
                                </div>
          

                            </div>
                            
                            <div class="row" style='width:60%; margin:0% auto;'>
                                <div class="col-md-12">
                                     <h3 class="section-title">Mobile Library (Library on Wheels)</h3>
                                     <span class="underline left"></span>
                                     <p>We offer mobile library services to unserved schools and communities across the
                                        country. GhLA currently has ten mobile library across the country.</p>
                                     
                                </div>

                                <div class="col-md-12">
                                    <h3 class="section-title">Reference Support Services</h3>
                                     <span class="underline left"></span>
                                     <p>Are you undertaking research and required some specific materials? Our reference
                                        section staff are available to help you. Contact any of our Regional Libraries.</p>
                                     
                                </div>
          

                            </div>
                            
                        
                            <div class="row" style='width:60%; margin:0% auto;'>
                                <div class="col-md-12">
                                     <h3 class="section-title">Printing, Scanning and Photocopying</h3>
                                     <span class="underline left"></span>
                                     <p>Printing, Scanning, Photocopying are services provide at most libraries in major cities.
                                        Contact your nearest library for more information.</p>
                                     
                                </div>

                                <div class="col-md-12">
                                    <h3 class="section-title">Issuance of ISBN, ISMN, ISNN, CIP</h3>
                                     <span class="underline middle"></span>
                                     
                                     
                                </div>
          

                            </div>

                            <div class="row" style='width:60%; margin:0% auto;'>
                                <div class="col-md-12">
                                     <h3 class="section-title">Legal Deposit</h3>
                                     <span class="underline left"></span>
                                     <p>Under Book and Newspaper Registration Act 1961, materials published in Ghana are
                                        required to deposit copies with the Ghana Library Authority. The deposited materials are
                                        used in building up the Ghana National Bibliography.</p>
                                     
                                </div>

                                <div class="col-md-12">
                                    <h3 class="section-title">Research</h3>
                                     <span class="underline middle"></span>
                                     <p>We undertake research on behalf of Government, Business and varied agencies. To
                                        sign up for any of our research service, do not hesitate to reach out to us.</p>
                                     
                                </div>
          

                            </div>

                            <div class="row" style='width:60%; margin:0% auto;'>
                                <div class="col-md-12">
                                     <h3 class="section-title">Library Consultancy (Schools, MDAs, Corporations)</h3>
                                     <span class="underline left"></span>
                                     <p>Are you desiring to construct or set up a library section within your institution? Sign up
                                        for our library consulting service and receive professional input in the development,
                                        design, management and stocking of your library.</p>
                                     
                                </div>

                                <div class="col-md-12">
                                    <h3 class="section-title">Co-Working Space</h3>
                                     <span class="underline middle"></span>
                                     <p>You need a space to work? Our co-working space provide an opportunity for
                                        entrepreneurs to sit in the comfort of like-minded people to develop their business. Our
                                        space provides a trusted location to enable your business find it balance within the
                                        ecosystem before moving out. Interested person should email us on
                                        <a href="#" style='color:blue;'> estates@library.gov.gh</a></p>
                                     
                                </div>
          

                            </div>
                            
                        </section>

                            <!-- <section class="company-info-boxx">
                                <div class="company-info">
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-md-10 aligncenter">
                                            <div class="col-md-6 border-dark-left">
                                                <div class="row">
                                                    <div class="col-md-1@">
                                                        <div class="company-detail bg-dark margin-left">
                                                            <h3 class="section-title">Study Rooms</h3>
                                                            <span class="underline left"></span>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur nec mauris a neque tincidunt iaculis. Sed tristique luctus sapien. Vestibulum arcu magna, ullamcorper quis porta ac, venenatis non ante. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Cras commodo sollicitudin felis vel porttitor. Quisque vitae egestas sapien.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur nec mauris a neque tincidunt iaculis. Sed tristique luctus sapien. Vestibulum arcu magna, ullamcorper quis porta ac, venenatis non ante. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Cras commodo sollicitudin felis vel porttitor. Quisque vitae egestas sapien.</p>
                                                            <a href="services.html#." class="btn btn-primary">Get a card</a>
                                                        </div>
                                                    </div>


                                                    <div class="col-md-12">
                                                        <div class="company-detail bg-dark margin-left">
                                                            <h3 class="section-title">Study Rooms</h3>
                                                            <span class="underline left"></span>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur nec mauris a neque tincidunt iaculis. Sed tristique luctus sapien. Vestibulum arcu magna, ullamcorper quis porta ac, venenatis non ante. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Cras commodo sollicitudin felis vel porttitor. Quisque vitae egestas sapien.</p>
                                                            <a href="services.html#." class="btn btn-primary">Get a card</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 border-dark">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="company-detail bg-light margin-right">
                                                            <h3 class="section-title">Books and more</h3>
                                                            <span class="underline left"></span>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur nec mauris a neque tincidunt iaculis. Sed tristique luctus sapien. Vestibulum arcu magna, ullamcorper quis porta ac, venenatis non ante. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Cras commodo sollicitudin felis vel porttitor. Quisque vitae egestas sapien.</p>
                                                            <a href="contact.html" class="btn btn-dark-gray">Make a request</a>
                                                        </div>
                                                    </div>


                                                    <div class="col-md-12">
                                                        <div class="company-detail bg-light margin-right">
                                                            <h3 class="section-title">Books and more</h3>
                                                            <span class="underline left"></span>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur nec mauris a neque tincidunt iaculis. Sed tristique luctus sapien. Vestibulum arcu magna, ullamcorper quis porta ac, venenatis non ante. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Cras commodo sollicitudin felis vel porttitor. Quisque vitae egestas sapien.</p>
                                                            <a href="contact.html" class="btn btn-dark-gray">Make a request</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section> -->



                            
                            <!-- Start: Category Filter -->
                        </div>
                    </div>
                </main>
            </div>
        </div>
        <!-- End: Services Section -->
