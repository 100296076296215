<div class='body'>
 
  


      <!-- ======= Hero Section ======= -->
  <section id="hero" class="d-flex align-items-center" style="background:#033600; padding-top:160px;">

    <div class='container'>
      <div class="row" style='margin:0% auto;'>
        
        <div class="col-md-8   hero-img" data-aos="zoom-in" data-aos-delay="200" style='color:white; margin:0% auto; text-align:center;'>
          <img src="../../../../assets/other-backgrounds/app.jpeg" class="img-fluid animated" alt="" >
          
          <h1>Access Our Digital Contents </h1>
          <h2>Download our digital library app on the google play and Apple stores.</h2>
          <div class="d-lg-flex" style='width:100%; margin-bottom:20px;'>
            <a href='https://play.google.com/store/apps/details?id=com.ghanalibrary.ghanalibrary&hl=en' target="_blank"> <img src="../../../../assets/other-backgrounds/googlePlay.png" class="img-fluid animated" alt="" style='height:130px; margin-top:-20px; margin-left:30px;'></a>
            <a href='https://apps.apple.com/gh/app/ghana-library/id1464674859' target="_blank"><img src="../../../../assets/other-backgrounds/appStore3.png" class="img-fluid animated" alt="" style='height:87px; width:300px; margin-left:30px;' ></a>
          </div>
        </div>
      </div>
    </div>

  </section><!-- End Hero -->
 
  </div>