import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
declare var $;
@Component({
  selector: 'app-audiobook-detail',
  templateUrl: './audiobook-detail.component.html',
  styleUrls: ['./audiobook-detail.component.css','./audiobook-detail.component.scss']
})
export class AudiobookDetailComponent implements OnInit {
  audiobook;
  playlist;
  currentFile:any;

  constructor(private activeroute:ActivatedRoute) { 
    // this.playlist[0] = this.currentFile;
    this.activeroute.queryParams.subscribe(params=>{
        this.audiobook = JSON.parse(params.audiobook)
        this.playlist = JSON.parse(this.audiobook.playlist)
        
    })
  }

  // selectNextAudio(){
  //   this.playlist[0] = this.currentFile;
  // }

  ngOnInit(): void {
    $('.fab-container').hide();
    $('.footer').hide()
  var viewportWidth = $(window).width();
  function comment(element)
  {
      element.html('<!--' + element.html() + '-->')
  }
  
  function uncomment(element){
      var len = element.html().length;
      element.html(element.html().substring(4, len - 3))
  }
  if (viewportWidth <= 991) {
    $('.mob-logo').hide();
    comment($('#dialog'));
    $('#dialog').modal('hide');
    $('.mobile').show();
    $('.breadcrumb-com').hide()
    $(' .breadcrumb-mob').show()
    $('.navbar-expand-lg').css('padding','7px');
  
    
  }else{
    $('#dialog').modal('show');
    comment($('.mobile'));
  }
  $('.backLink').click(function(){
    parent.history.back();
    return false;
    
});
  //   $('.backLink').click(function(){
  //     $('.fab-container').show();
  //     parent.history.back();
  //     return false;
  // });
  }

}
