<main id="main">

    

    <!-- ======= Pricing Section ======= -->
    <section id="pricing" class="pricing news">
       <div class="container" data-aos="fade-up" style='height:250px;'>
 
        
 
       </div>
     </section><!-- End Pricing Section -->
 <!-- ======= Breadcrumbs ======= -->
 <section id="breadcrumbs" class="breadcrumbs">
   <div class="container">

     <ol>
       <li><a routerLink='/'>Home</a></li>
       <li>Location Finder</li>
     </ol>
     <h2>Location Finder</h2>

   </div>
 </section><!-- End Breadcrumbs -->

   <!-- ======= Portfolio Details Section ======= -->
   <section id="portfolio-details" class="portfolio-details">
    
    <section id="contact" class="contact">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Location Finder</h2>
          <p>Ghana Library Authority has branches accross the country Ghana. Find the closest library near you.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 align-items-stretch">
                <ul class="list-group branches">
                  <ul class='list-group e-file-loader'>

                  </ul>

                    <li *ngFor="let library of libraries" class="list-group-item">
                      <a (click)="loadMap(library)" style='color:black;cursor: pointer;'>
                        <p style='height:5px; color:#033600; font-weight:600;'>{{library.name}}</p>
                        <p style='height:5px;'>{{library.gps_code}}</p>
                        <p style='height:5px; font-size:12px;'>{{library.address}}</p>
                      </a>
                    </li>
                    </ul>
            </div>

          <div class="col-lg-7 d-flex align-items-stretch map">
            <div class="info" style='padding:0px;'>
              <agm-map [latitude]="lat" [longitude]="lng">
                <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
              </agm-map>

            </div>

          </div>

         
        </div>

      </div>
    </section>
    
    <!-- End Contact Section -->

   </section><!-- End Portfolio Details Section -->

 </main><!-- End #main -->



 



      

       