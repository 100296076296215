import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
declare var $;

@Component({
  selector: 'app-video-detail',
  templateUrl: './video-detail.component.html',
  styleUrls: ['./video-detail.component.css']
})
export class VideoDetailComponent implements OnInit {

  video;
  videoSrc;

  playlist;

  constructor(private activeroute:ActivatedRoute) { 
    this.activeroute.queryParams.subscribe(params=>{
        this.video = JSON.parse(params.video)

        this.playlist = JSON.parse(this.video.playlist)
    })
  }

  ngOnInit(): void {
    $('.fab-container').hide();
    $('.footer').hide()
    var viewportWidth = $(window).width();
    function comment(element)
{
		element.html('<!--' + element.html() + '-->')
}

function uncomment(element){
		var len = element.html().length;
		element.html(element.html().substring(4, len - 3))
}
  if (viewportWidth <= 991) {
    $('.mob-logo').hide();
    comment($('#dialog'));
    $('#dialog').modal('hide');
    $('.mobile').show();
    $('.breadcrumb-com').hide()
    $(' .breadcrumb-mob').show()
    $('.navbar-expand-lg').css('padding','7px');
  }else{
    $('#dialog').modal('show');
    comment($('.mobile'));
  }
  $('.backLink').click(function(){
    parent.history.go(-1);
    return false;
    
});
  //   $('.backLink').click(function(){
  //     parent.history.back();
  //     $('.fab-container').show();
  // });
    this.videoSrc = this.playlist[0].url
  }
  
}
